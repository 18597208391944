<template>
  <div>
    <div v-if="!uses && !doctrine && !juri && !legislation">
      <div class="columns">
        <div class="column">
          <h2 class="is-title is-size-4 has-text-centered">Índices</h2>
        </div>
      </div>
      <div class="columns">
        <div class="column" @click="uses = true">
          <archive-button :text="'Usos e Costumes'" :size="'large'"/>
        </div> 
        <div class="column" @click="doctrine = true">
          <archive-button :text="'Doutrina'" :size="'large'"/>
        </div>
      </div>
      <div class="columns">
        <div class="column" @click="juri = true">
          <archive-button :text="'Jusrisprudência'" :size="'large'"/>
        </div>
        <div class="column" @click="legislation = true">
          <archive-button :text="'Legislação'" :size="'large'"/>
        </div>
      </div>
      <div class="field">
      <div class="control">
        <button class="button is-primary" @click="goBack">Voltar</button>
      </div>
    </div>
    </div>
    <uses v-if="uses" @goBack="uses = false"></uses>
    <doctrine v-if="doctrine" @goBack="doctrine = false"></doctrine>
    <juri v-if="juri" @goBack="juri = false"></juri>
    <pluralism-legislation v-if="legislation" @goBack="legislation = false"></pluralism-legislation>
  </div>

</template>

<script>
import ArchiveButton from "../components/generic/ArchiveButton";
import Uses from "../components/partial/Uses";
import Doctrine from "../components/partial/Doctrine";
import Juri from "../components/partial/Juri";
import PluralismLegislation from "../components/partial/PluralismLegislation.vue";
export default {
  name: "ColonialIndexes",
  components: { Juri, Doctrine, Uses, ArchiveButton, PluralismLegislation },
  data:()=>({
    uses: false,
    doctrine: false,
    juri: false,
    legislation: false,
  }),
  methods:{
    goToSearch(){
      this.$router.push({name: 'Search'});
    },
    goBack(){
      this.$router.push({name: 'Archive'});
    }
  }
}
</script>

<style scoped>
.is-bottom-line{
  width:80%;
  margin-left: auto;
  margin-right: auto;
}

.button.is-primary{
  background-color: #f5993d;
}

.button.is-primary:hover{
  background-color: #ff8000;
}
</style>