<template>
<div>
  <div class="box has-text-justified">
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Palavra a pesquisar</label>
          <div class="control">
            <input class="input" type="text" v-model="term" placeholder="Palavra a pesquisar">
          </div>
          <p class="help">Palavra a pesquisar nos documentos</p>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <div class="field">
          <label class="label">Modo de Pesquisa</label>
          <div class="select is-fullwidth">
            <select v-model="searchMode">
              <option v-for="(searchMode, index) in searchModes" :key="index" :value="searchMode">
                {{searchMode.description}}
              </option>
            </select>
          </div>
          <p class="help">Modo de pesquisa dos termos</p>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="field">
          <label class="label">Abrangência da Pesquisa</label>
          <div class="select is-fullwidth">
            <select v-model="searchPlace">
              <option v-for="(searchPlace, index) in searchPlaces" :key="index" :value="searchPlace">
                {{searchPlace.description}}
              </option>
            </select>
          </div>
          <p class="help">Local onde pesquisar os termos</p>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-two-thirds">
        <div class="field">
          <label class="label">Documento</label>
          <div class="select is-fullwidth">
            <select v-model="document" @change="clearDates">
              <option :value="{}"></option>
              <option v-for="(document, index) in documents" :key="index" :value="document">
                {{ document.title }} [{{document.date.year}}]
              </option>
            </select>
          </div>
          <p class="help">Documento onde efetuar a pesquisa</p>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <fieldset :disabled="Object.keys(document).length !== 0 && document.constructor === Object">
          <div class="field">
            <label class="label">Ano</label>
            <div class="columns is-year-selector">
              <div class="column is-2 is-align-items-center is-flex is-year-element">
                <p class="is-year-label">De:</p>
              </div>
              <div class="column is-year-element">
                <input class="input" type="number" v-model.number="yearStart" placeholder="Ano">
              </div>
              <div class="column is-2 is-align-items-center is-flex is-year-element">
                <p class="is-year-label">Até:</p>
              </div>
              <div class="column is-year-element">
                <input class="input" type="number" v-model.number="yearEnd" placeholder="Ano">
              </div>
            </div>
            <p class="help">Anos limite da pesquisa</p>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <button class="button is-primary" @click="search">Pesquisar</button>
      </div>
    </div>
  </div>
  <div class="field">
    <div class="control">
      <button class="button is-primary" @click="goBack">Voltar</button>
    </div>
  </div>
  <waiting-overlay v-if="isWait"/>
  <message-overlay :overlay-message="message" :information-type="'is-danger'"
                   v-if="hasMessage" @closed="hasMessage = false"/>
</div>
</template>

<script>
import WaitingOverlay from "../generic/WaitingOverlay";
import MessageOverlay from "../generic/MessageOverlay";
export default {
name: "SearchForm",
  components: {MessageOverlay, WaitingOverlay},
  data:()=>({
    term: '',
    documents: [],
    document: {},
    searchMode: {},
    searchModes: [{id:1, description:'Palavras na mesma página'}, {id:2, description:'Toda a expressão'}],
    searchPlace: {},
    searchPlaces: [{id:1, description:'Apenas nos índices'}, {id:2, description:'Em todo o texto'}],
    yearStart: '',
    yearEnd: '',
    isWait: false,
    hasMessage: false,
    message: 'Nenhum campo submetido'
  }),
  methods:{
    async search(){
      if(!this.hasCriteria()){
        this.message = 'Nenhum campo submetido';
        this.hasMessage = true;
      }else if(this.term === ''){
        this.message = 'Nenhuma palavra a pesquisar';
        this.hasMessage = true;
      }else{
        this.isWait = true;
        await this.searchTerm();
        this.isWait = false;
      }
    },
    async searchTerm(){
      let result = {term:this.term, searchMode:this.searchMode, searchPlace:this.searchPlace, result:[]};
      const opts = {
        method: 'POST',
        headers: {'Content-Type': 'application/json; charset=UTF-8"'},
        body: JSON.stringify({term:this.term, type:this.searchMode.id, place:this.searchPlace.id, document:this.document,
          yearStart:this.yearStart, yearEnd:this.yearEnd})
      };
      const res = await fetch('/pluralismo-juridico/API/public/index.php/SearchAPI/searchTerm', opts).
      then(res => res.json());
      if(res.pages.length > 0){
        result.result = res.pages;
        this.$emit('searched', result);
        this.term = '';
      }else{
        this.$emit('searched', result);
      }
    },
    async getDocuments(){
      const opts = {
        method: 'POST',
        headers: {'Content-Type': 'application/json; charset=UTF-8"'},
      };
      const res = await fetch('/pluralismo-juridico/API/public/index.php/SearchAPI/getAllDocuments', opts).
      then(res => res.json());
      if(res !== null){
        this.documents = res.documents;
      }else{
        this.documents = []
      }
    },
    hasCriteria(){
      if((this.term !== '') || (this.yearStart !== '') || (this.yearEnd !== '')
          || (Object.keys(this.document).length !== 0 && this.document.constructor === Object)){
        return true;
      }else {
        return false;
      }
    },
    goBack(){
      this.$emit('goBack')
    },
    clearDates(){
      this.yearStart = '';
      this.yearEnd = '';
    }
  },
  async created() {
    this.isWait = true;
    this.searchMode = this.searchModes[0];
    this.searchPlace = this.searchPlaces[0];
    await this.getDocuments();
    this.isWait = false;
  }
}
</script>

<style scoped>
.button.is-primary{
  background-color: #f5993d;
}

.button.is-primary:hover{
  background-color: #ff8000;
}

.is-year-selector{
  margin-bottom: 0;
}

.is-year-element{
  padding-bottom: 0;
}
</style>