<template>
  <div>
    <h2 class="is-title is-size-4">Links Úteis</h2>
    <div class="field">
      <div class="control">
        <button class="button is-primary" @click="goBack">Voltar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Links",
  methods:{
    goBack(){
      this.$emit('goBack')
    },
  },
}
</script>

<style scoped>
.button.is-primary{
  background-color: #f5993d;
}

.button.is-primary:hover{
  background-color: #ff8000;
}
</style>