<template>
<div>
  <div class="box" >
    <div class="columns">
      <div class="column">
        <p>Título: {{document.title}}</p>
        <p>Ano: {{displayDate(document.date)}}</p>
      </div>
    </div>
    <div class="columns is-result" :style="calcHeight()">
      <div class="column">
        <p>Resultados: </p>
        <p v-for="n in maxResult()" :key="n">
          <span v-html="'Página: ' + filterResults()[n-1].page + ' - [...] ' + filterResults()[n-1].text + ' [...]'"></span>
          <a @click="showPage(document.id, filterResults()[n-1].page)"> (ver ocorrência) </a>
        </p>
      </div>
      <!--<div class="column is-one-fifth">
        <img :src="displaySource()">
      </div>-->
    </div>
    <div class="columns">
      <div class="column">
        <page-counter :instances-per-page="resultsPerPage" :total-instances="results.length" :current-page="currentPage"
                      @changePage="changePage" v-if="results.length > resultsPerPage"/>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <button class="button is-display-button is-primary" @click="viewDocument(document.id)">Ver volume</button>
      </div>
    </div>
    <!--<PDFJSViewer class="is-viewer" :path="path" :file-name="document.document.file"
                 :page="document.number" :term="term"/>-->
  </div>
</div>
</template>

<script>
//import PDFJSViewer from "./PDFJSViewer";
import PageCounter from "../generic/PageCounter";
export default {
name: "DocumentResult",
  components: {PageCounter},
  props:{
    document:{
      type: Object,
      required: true,
    },
    term:{
      type: String,
      required: true,
    },
    searchMode: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      path: '/pics', //path of the PDF.js viewer.html,
      results: [],
      limits: [-60, 60],
      resultsPerPage: 20,
      currentPage: 0,
      maxResults: 0,
    }
  },
  methods:{
    displayDate(year){
      return year.year
    },
    viewDocument(id){
      this.$emit('view', id)
    },
    displaySource(){
      return this.path + '/' + this.document.file.slice(0,-3) + 'png'
    },
    getResults(){
      let results = [];
      let term = this.term;
      if(this.searchMode.id === 1){
        term = term.split(' ');
        term = term[0];
      }
      for(let i=0; i<this.document.pages.length; i++){
        let result = [];
        let regex = new RegExp(term, 'ig');
        //while (result.length === 0) {
        while ((result = regex.exec(this.document.pages[i].text))) {
          let low = 0;
          if(result.index + this.limits[0] > 0){
            low = result.index + this.limits[0];
          }
          let text = this.document.pages[i].text.slice(low, result.index) +  '<b>' +
              this.document.pages[i].text.slice(result.index, result.index + term.length) + '</b>'
              + this.document.pages[i].text.slice(result.index + term.length, result.index + this.limits[1])
          let occurrence = {page:this.document.pages[i].number,
            text:text}
          results.push(occurrence);
        }
      }
      this.results = results;
    },
    showPage(id, page){
      this.$emit('goto', {id:id, page:page})
    },
    changePage(page) {
      this.currentPage = page;
    },
    filterResults(){
      let documents = [];
      for(let i=this.currentPage*this.resultsPerPage; i<this.results.length; i++){
        documents.push(this.results[i])
      }
      return documents
    },
    maxResult(){
      let results = Math.min(this.resultsPerPage, this.results.length, this.results.length-this.currentPage*this.resultsPerPage)
      return results
    },
    calcHeight(){
      let height = 44;
      height += 22*this.maxResults;

      return "height: " + height + "px;"
    }
  },
  watch: {
    // whenever question changes, this function will run
    document(){
      this.getResults();
      this.maxResults = Math.min(this.results.length, this.resultsPerPage);
      this.currentPage = 0;
    }
  },
  created(){
    this.getResults();
    this.maxResults = Math.min(this.results.length, this.resultsPerPage);
  }
}
</script>

<style scoped>
.is-viewer{
  height: 15em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
}

.is-display-button{
  margin-top: 2em;
}

.button.is-primary{
  background-color: #f5993d;
}

.button.is-primary:hover{
  background-color: #ff8000;
}

.is-result{
  /*height: 500px;*/
}
</style>