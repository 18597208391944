<template>
  <div>
    <iframe height="100%" width=100% :src="getFilePath()" style="overflow:hidden;"></iframe>
  </div>
</template>

<script>
export default {
  name: 'PDFJSViewer',
  props: {
    fileName: {
      type: String,
    },
    path: {
      type: String
    },
    term:{
      type: String,
      required: true,
    },
    page:{
      type: Number,
      required: true,
    }
  },
  computed:{
  },
  methods:{
    getFilePath(){
      let path = this.path;
      if(this.fileName !== ''){
        path += '?file=pdf/' + this.fileName;
        if(this.page !== 0){
          path += '#page=' + this.page
        }
        if(this.term !== ''){
          path += '&search=' + this.term
        }
      }
        return path
    },
  },
  created() {
  }
}
</script>
<style scoped>
</style>