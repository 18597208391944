<template>
  <div>
    <h2 class="is-title is-size-3">Legislação (coleções, reportórios e índices) </h2>
    <div class="columns">
      <div class="column has-text-centered">
        <p class="is-index">
          <a href="https://pluralismojuridiconoimperio.fd.unl.pt/pdfs/Índice_Legislação colonial.pdf" 
             target="_blank" 
             class="is-title is-size-4">
            Índice de Legislação (coleções, reportórios e índices)  (pdf)
          </a>
        </p>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <button class="button is-primary" @click="goBack">Voltar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PluralismLegistation",
  methods:{
    goBack(){
      this.$emit('goBack')
    },
  },
}
</script>

<style scoped>
.button.is-primary{
  background-color: #f5993d;
}

.button.is-primary:hover{
  background-color: #ff8000;
}

.is-index {
  margin-top: 3em;
}
</style>