<template>
  <div>
    <h2 class="is-title is-size-3">Usos e Costumes</h2>
    <div class="text-div" v-if="false">
      <p>ALMADA, André Alvares d' (autor), e Köpke, Diogo (editor), <i>Tratado breve dos Rios de Guiné do Cabo-Verde desde o 
        Rio do Sanagá até aos baixos de Sant'Anna,</i> Porto, Typ. Commercial Portuense, 1841 [1594].</p>
      <p>ALMEIDA, António de - Esboço histórico das organizações tradicionais dos regulados indígenas de Angola e de 
        Moçambique, ln: <i>Congresso do Mundo Português</i>, 9, Lisboa, 1940 - Actas, tomo II, 1940, vol. 15, p. 527-542.</p>
      <p>ANDRADE, José Ignacio de, <i>Cartas escriptas da India e da China, nos anos de 1815 a 1835</i>, 2 tomos, Lisboa, 
        Imprensa Nacional, 1843.</p>
      <p>
        ANDRADE, P. Viana de - Curila (Costumes do Luiana-Cuando), In <i>Mensário Administrativo</i>, Luanda, 7, 1949, 
        p. 15-17.</p>
      <p>ANÓNIMO, «Bases para um regulamento de julgamento de milandos», in <i>Província de Moçambique. Relatórios e 
        informações. Anexo ao “Boletim Official”</i>, Lourenço Marques, Imprensa Nacional, 1910, pp. 16-21.</p>
      <p>ANÓNIMO, <i>Circunscrições de Lourenço Marques: respostas aos quesitos feitos pelo secretário dos negócios 
        Indígenas, Dr. Francisco Ferrão, para a confecção do relatório sobre o distrito de Lourenço Marques</i>, 
        Lourenço Marques, Imprensa nacional, 1908.</p>
      <p>ANÓNIMO, <i>Codigo das communidades: approvado em portaria provincial no 315 de 1 de Dezembro de 1904</i>, 
        Nova Goa: Imprensa Nacional, 1905.</p>
      <p>ANÓNIMO, <i>Código dos milandos cafriaes (Districto de Inhambane)</i>, parte 1, <i>Boletim da Sociedade 
        de Geographia de Lisboa</i>, 1, 3 (1878), 171-181.</p>
      <p>ANÓNIMO, <i>Código dos milandos inhambanenses (Litígios e pleitos)</i>, aprovado por portaria provincial
         nº 269 de 11 de maio de 1889, Moçambique, Imprensa Nacional, 1889.</p>
      <p>ANÓNIMO, <i>Código dos usos e costumes dos habitantes das novas conquistas, em Portuguez e Maratha, 
        acompanhado dos respectivos índices. Segunda Edição, Correscta, e Accrescentada, com uma collecção 
        de providencias relativas aos Gentios das Velhas-Conquistas</i>, Nova Goa, Imprensa Nacional, 1861.</p>
      <p>ANÓNIMO, <i>Código dos uzos e costumes dos habitantes não-christãos de Damão</i>, Nova Goa, Imprensa 
        Nacional, 1854.</p>
      <p>ANÓNIMO, <i>Colónia de Moçambique, territoire de Manica e Sofala (sous l'administration de la 
        "Companhia de Moçambique"</i>, Edition pour L'Exposition Coloniale Internationale de 1931 à Paris, Paris, 
        Exposition Coloniale internationale, 1931.</p>
      <p>ANÓNIMO, <i>Costumes Angolenses - Cartas de um Judeu</i>, S.l, 1876</p>
      <p>ANÓNIMO, «Informações sobre alguns usos e costumes indígenas das circunscrições [Chiloane, Gorongoza, 
        Govuro, Manica, Mossurize, Neves Ferreira, Senna, Sofala], in <i>Província de Moçambique. Relatórios e 
          informações. Anexo ao “Boletim Official”</i>, Lourenço Marques, Imprensa Nacional, 1910, pp. 52-196.</p>
      <p>ANÓNIMO, <i>Projecto de regulamento para o julgamento de "Milandos"</i>, Lourenço Marques, Imp. 
        Nacional, 1907.</p>
      <p>ANÓNIMO, <i>Quarenta e Cinco Dias em Angola, Apontamentos de Viagem</i> (Porto: Typ. Sebastião 
        José Pereira, 1862).</p>
      <p>ANÓNIMO, <i>Questionário Etnographico Acerca das Populações Indígenas de Angola e Congo</i>, Luanda, 
        Imprensa Nacional de Angola, 1912.</p>
      <p>ANÓNIMO, <i>Regulamento local do trabalho dos indígenas no distrito de Mossâmedes</i>, Luanda, 
        Imprensa Nacional, 1918.</p>
      <p>ANÓNIMO, <i>Regulamento para o Capitão-Mor da Villa de Quelimane e seu termo</i>, aprovado pelo Governador 
        Geral de Moçambique a 4 de Junho de 1853, transcrito em CUNHA, Joaquim d'Almeida da, <i>Estudo acerca dos usos 
          e costumes dos Banianes, Bathias, Parses, Mouros, Gentios e Indígenas</i> [Para o cumprimento do que dispõe 
          o artigo 8º, §1º do decreto de 18 de novembro de 1869], Moçambique, Imprensa Nacional, 1885, pp. XIV-XVI.</p>
      <p>ANÓNIMO, <i>Termos de vassallagem nos territorios de Machona, Zambezia e Nyassa: 1858 a 1889</i>, Lisboa, 
        Imprensa Nacional, 1890.</p>
      <p>ANÓNIMO, «Usos e costumes de Goa», In <i>Boletim Geral do Ultramar</i>, nº 427-428, ano XXXVII, Lisboa, 
        Agência Geral do Ultramar, 1961, p. 239-259.</p>
      <p>ARCHER, Maria, <i>Africa selvagem: folclore dos negros do grupo "Bantu"</i>, Lisboa, Editora Guimarães, 1936.</p>
      <p>ARCHER, Maria, <i>Terras onde se fala Português</i>, Rio de Janeiro, Casa do Estudante do Brasil, 1957.</p>
      <p>AZEREDO, Guilhermina de, <i>Feitiços</i>, Lisboa, 1935.</p>
      <p>AZEVEDO, António Emílio Almeida, <i>As Comunidades de Goa</i>, História das Instituições Antigas, Lisboa, 1890.</p>
      <p>BARROS, Marcelino Marques de, «Guiné Portuguesa ou breve notícia sobre alguns dos seus usos, costumes, línguas e 
        origens de seus povos», In <i>Boletim da Sociedade de Geografia de Lisboa</i>, série 3, nº 12 (Dez. 1882), p. 
        707-731.</p>
      <p>BARROS, Marcelino Marques de, «Guiné Portuguesa ou breve notícia sobre alguns dos seus usos, costumes, línguas e 
        origens de seus povos», In <i>Anais das Missões Ultramarinas</i>, Lisboa, 1889, p. 143-154.</p>
      <p>BARROW, John - «Camussequeres. Alguns aspectos da sua vida», In <i>Mensário Administrativo</i>, Luanda, 14, 
        1948, p. 23-24.</p>
      <p>BASTOS, Alberto Celestino Ferreira Pinto, “Subsidios para um codigo de usos e costumes indigenas no território”, 
        <i>Boletim da Companhia de Moçambique - Governo do Territorio de Manica e Sofala</i>, 16 (1909), 116-125.</p>
      <p>BOTELHO, José Justino Teixeira [et al.], «Direito Consuetudinário dos indígenas de Timor», In <i>Memórias da 
        Academia das Ciências de Lisboa</i>, Classe de Letras, Tomo I (1936), p. 203-226.</p>
      <p>BROCHADO, Bernardino José, "Descripção das Terras do Humbe, Camba, Molundo, Cuanhama e outras contendo uma ideia 
        da sua População, seus Costumes, Vestuaria, etc." [1850] in <i>Annaes do Conselho Ultramarino</i>, Série I (1854-1858), 
        Lisboa, Imprensa Nacional, 1867, p. 187-197.</p>
      <p>CABRAL, António Augusto Pereira, <i>Raças, Usos e Costumes dos Indígenas de Inhambane, acompanhado de um 
        vocabulário em shitsua, guitonga e shishope</i>, Lourenço Marques, Imprensa Nacional, 1910.</p>
      <p>CABRAL, António Augusto Pereira, <i>Raças, Usos e Costumes dos Indígenas de Moçambique</i>, Lourenço Marques, 
        Imprensa Nacional, 1925.</p>
      <p>CABRITA, Carlos L. Antunes, <i>Em terras de Luenas: Breve estudo sobre os usos e costumes da tribo Luena</i>, 
        Lisboa, Agência Geral do Ultramar, 1954.</p>
      <p>CALVET de MAGALHÃES, Vasco de Souza, <i>Província da Guiné, Relatório</i>, Porto, Tipografia Progresso, 1914.</p>
      <p>CARDOSO, Augusto, «Projecto do código de costumes cafreaes, apresentado pelo governador do districto de Inhambane, 
        Augusto Cardoso», in <i>Província de Moçambique. Relatórios e informações. Anexo ao “Boletim Official”</i>, 
        Lourenço Marques, Imprensa Nacional, 1910, pp. 26-33.</p>
      <p>CARDOSO, Carlos Lopes, <i>A Origem dos Mbalis do Distrito de Moçâmedes</i> (Lisbon: Revista Ultramar, 1964).</p>
      <p>CARVALHO, Henrique de, <i>Ethnographia e história tradicional dos povos da Lunda</i>, Lisboa, Imprensa 
        Nacional, 1890.</p>
      <p>CASTELBRANCO, Hermínio, «Usos e costumes dos povos de Ambaca», In <i>Mensário Administrativo</i>, 41-42, 
        1951, p. 39-42.</p>
      <p>CASTRO, Afonso de, «Notícias dos usos e costumes dos povos de Timor, extraída do relatório do SR. Affonso de 
        Castro, Governador daquela possessão portugueza», In <i>Anais do Conselho Ultramarino</i>, parte não oficial, 
        Lisboa, 4ª série, 1863, p. 28-42.</p>
      <p>CAROÇO, Jorge Frederico Torres Velez, <i>Questionário Etnográfico</i>, Apenso ao Boletim Oficial da Guiné 
        Portuguesa, 20, 14 de Maio de 1934.</p>
      <p>CARREIRA, António, <i>Mutilações étnicas dos Manjacos</i>, Bissau, Boletim Cultural da Guiné 
        Portuguesa, 1961.</p>
      <p>CARREIRA, António, <i>Símbolos, ritualistas e ritualismos ânimo-feiticistas na Guiné Portuguesa</i>, Bissau, 
        Boletim Cultural da Guiné Portuguesa, 1961.</p>
      <p>CARREIRA, António, <i>O infanticídio ritual em África</i>, Bissau, Boletim Cultural da Guiné Portuguesa, 1971.</p>
    </div>
    <div class="columns">
      <div class="column has-text-centered">
        <p class="is-index">
          <a href="https://pluralismojuridiconoimperio.fd.unl.pt/pdfs/Índice_«usos e costumes».pdf" 
             target="_blank" 
             class="is-title is-size-4">
            Índice «usos e costumes» (pdf)
          </a>
        </p>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <button class="button is-primary" @click="goBack">Voltar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Uses",
  methods:{
    goBack(){
      this.$emit('goBack')
    },
  },
}
</script>

<style scoped>
.button.is-primary{
  background-color: #f5993d;
}

.button.is-primary:hover{
  background-color: #ff8000;
}

.text-div {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.is-index {
  margin-top: 3em;
}
</style>