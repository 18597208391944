<template>
  <div>
    <h2 class="is-title is-size-4">Equipa</h2>
    <div class="is-team">
      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/CNS.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Cristina Nogueira da Silva</b></p>
          <p class="has-text-centered">(Investigadora Responsável)</p>
        </div>
        <div class="column">
          <p class="block">Cristina Nogueira da Silva nasceu em Lisboa, a 29 de Abril de 1967.  É Professora na
            Faculdade de Direito da Universidade Nova de Lisboa, onde lecciona várias disciplinas de História e a Disciplina
            Direito e Sociedade, no terceiro ciclo. É licenciada em História (Faculdade de Ciências Sociais e Humanas da
            Universidade Nova de Lisboa, 1985-89). Concluiu o Mestrado em Ciências Sociais (História e Sociologia do Poder) no
            Instituto de Ciências Sociais da Universidade Lisboa (1997), doutorou-se em História do Direito na Faculdade de
            Direito da Universidade Nova de Lisboa (2005). Foi membro da equipa de investigação de vários projetos sobre história
            institucional e política da época moderna e contemporânea (no Instituto de Ciências Sociais da Universidade de Lisboa,
            no Arquivo Nacional da Torre do Tombo e na Faculdade de Direito da Universidade Nova de Lisboa). </p>

          <p class="block">Actualmente as suas áreas de investigação são o pensamento liberal, a cidadania e a história do estatuto jurídico dos
            territórios e populações do Império português nos séculos XIX-XX, temas sobre o quais publicou dois livros individuais
            (Constitucionalismo e Império. A cidadania no Ultramar português, Coimbra, Almedina, 2010; A Construção jurídica dos
            territórios ultramarinos portugueses no século XIX, Modelos, Doutrinas e Leis, Lisboa, Imprensa de Ciências Sociais,
            2017) e quatro livros em co-coordenação, além da coordenação de dois projectos de investigação e da publicação de
            vários artigos, em Portugal e no estrangeiro. Publicações e outros trabalhos estão disponíveis
            <a href='https://docentes.fd.unl.pt/docentes_docs/cv/CV_10011.pdf.' target='_blank'>aqui</a>.
          </p>
          <p><a @click="showElement(CNS)">Publicações relevantes</a></p>
        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/ABX.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Ângela Barreto Xavier</b></p>
          <p class="has-text-centered">(Co-Coordenadora)</p>
        </div>
        <div class="column">
          <p class="block">Ângela Barreto Xavier é Investigadora do Instituto de Ciências Sociais da Universidade de Lisboa.
            Doutorada em História e Civilização pelo Instituto Universitário Europeu, de Florença, é mestre em História e Política
            e Cultural e licenciada em História e História da Arte pela Universidade Nova de Lisboa. Leccionou na Faculdade de
            Ciências Sociais e Humanas da Universidade Nova de Lisboa, no Instituto Superior de Ciências do Trabalho e da Empresa,
            foi Maître de Conférences Invitée na École des Hautes Études en Sciences Sociales, e colabora regularmente com a
            Universidad Complutense de Madrid. Foi ainda Visiting Scholar do Departamento de História da Universidade de Harvard.</p>

          <p class="block">Ao longo dos últimos anos tem privilegiado a história das ideias políticas, nomeadamente as articulações entre
            projectos políticos e mecanismos de inclusão e exclusão (política, social, cultural), bem como a história cultural dos
            impérios da época moderna, aí privilegiando a produção, selecção e circulação de saberes. Nesse âmbito, tem coordenado
            e participado em vários projectos e publicado extensivamente.</p>

          <p class="block">
            Entre os seus principais livros, destacam-se <i>El-rei aonde pòde e não onde quer. Razões da Política no Portugal
            Seiscentista</i> (1998), <i>A Invenção de Goa. Poder Imperial e Conversões Culturais</i> (2008), <i>Catholic
            Orientalism. Portuguese Empire, Indian Knowledge, 16th-18th centuries </i> (2014), com Ines G. Županov; <i>O Governo
            dos Outros. Poder e Diferença no Império Português, sécs. XVI-XX</i> (2016), coord. com Cristina Nogueira da Silva;
            <i>Monarquias Ibéricas em Perspectiva Comparada, Dinâmicas Imperiais e Circulação de Modelos Administrativos, sécs.
              XV-XVIII</i> (2018).
          </p>

          <p class="block">
            Para mais informações sobre Ângela Barreto Xavier e acesso às suas publicações online, veja:
            <a href='https://lisboa.academia.edu/%C3%82ngelaBarretoXavier' target='_blank'>aqui</a> e
            <a href='https://www.ics.ulisboa.pt/pessoa/angela-barreto-xavier' target='_blank'>aqui</a>.
          </p>

          <p><a @click="showElement(ABX)">Contactos</a></p>
        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/APB.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Anabela Paula Brízido</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">

          <p class="block">Anabela Paula Brízido é licenciada em Direito pela Universidade Internacional, Mestre em Direito
            pela Faculdade de Direito da Universidade Nova de Lisboa (FDUNL), doutoranda em Direito nesta mesma Faculdade viu
            atribuída em 2017, uma bolsa de investigação pelo Fundo de Ciência e Tecnologia (FCT) e Investigadora do CEDIS.</p>

          <p class="block">É Advogada, com prática efetiva, desde 1997 e Formadora desde 2008, em diferentes áreas do direito, incluindo, as
            relacionados com sistemas jurídicos africanos, concretamente, Angola, Moçambique e São Tomé. Presentemente aquelas
            atividades encontram-se suspensas. </p>

          <p class="block">As suas áreas de interesse são Sujeitos Não Estaduais; Tradições Jurídicas Ctónicas; Normação Privada; Direito
            Internacional Público, Direitos Humanos e Direito dos Conflitos Armados.
          </p>

        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/FT.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Fernanda Thomaz</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">Professora de História da África da Universidade Federal de Juiz de Fora (UFJF - Brasil) e membro
            permanente credenciada ao Programa de Pós-Graduação em História da UFJF. Doutora em História pela Universidade Federal
            Fluminense, com a tese de título: “O casaco que despe pelas costas – formação da justiça colonial e a (re) ação dos
            africanos no norte de Moçambique”.  Coordenadora do grupo de pesquisa Afrikas e Vice-coordenadora do Laboratório de
            História Oral e Imagem – rede de pesquisa (LABHOI/UFJF). Tem experiência na área de História da África, com ênfase em
            Moçambique na época colonial, atuando especificamente nos seguintes temas: história social da justiça, relações raciais,
            gênero e sexualidade.</p>

          <p><a @click="showElement(FT)">Publicações relevantes</a></p>

        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/JF.png">
          <p class="has-text-centered" style='font-size: 110%'><b>João Figueiredo</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">João Figueiredo é doutorado em Altos Estudos em História, Ramo Império, Política e Pós-colonialismo pela
            Faculdade de Letras da Universidade de Coimbra (2016), pós-graduado em Direitos Humanos pela Faculdade de Direito da
            Universidade de Coimbra (2006), e licenciado em Antropologia, pela Faculdade de Ciências e Tecnologia da Universidade
            de Coimbra (2005). O seu trabalho tem-se focado na análise histórica e antropológica do colonialismo Português em
            Angola durante o ‘longo do século XIX’. Tem especial interesse nas interfaces entre a administração portuguesa e os
            sistemas normativos locais, e no papel que a materialidade, quer da escrita, quer dos objetos etnográficos,
            desempenhou nestas relações. Lecionou e trabalhou como consultor na Universidade Piaget de Luanda (2009), prestou
            serviços enquanto antropólogo à autarquia de Lamego (2016 – 2018), traduziu do inglês para o português uma monografia
            sobre Moçambique (2018) e colabora com o Serviço Educativo do Museu do Douro (2017 – ). Autor de vários capítulos e de artigos em
            revistas de peer-review como Cadernos de Estudos Africanos ou Social Sciences and Missions, prepara um manuscrito para
            publicação a partir da sua dissertação de tese de doutoramento, Política, Escravatura e Feitiçaria em Angola
            (séculos XVIII-XX).</p>

          <p><a @click="showElement(JF)">Publicações relevantes</a></p>

        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/MCN.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Maria da Conceição Neto</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">Maria da Conceição Neto, angolana, é professora de História de Angola na Universidade Agostinho Neto
            desde 1989, leccionando actualmente na Faculdade de Ciências Sociais desta Universida (Luanda). É doutorada em História
            de África pela SOAS (Universidade de Londres, 2012) com uma tese sobre história social urbana do Huambo (Angola)
            (<a href="http://eprints.soas.ac.uk/13822/1/Neto_3375.pdf" target="_blank">eprints.soas.ac.uk/13822/1/Neto_3375.pdf</a>). Foi investigadora (part-time) no Arquivo Nacional de Angola (1990-2003) e
            investigadora convidada no CEAN (Bordéus 1999) e na EHESS (Paris, 2009). É investigadora associada do Instituto de
            História Contemporânea da Universidade Nova de Lisboa. Assegurou a consultoria histórica do Projecto da Associação
            Tchiweka de Documentação "Angola – nos Trilhos da Independência" (2010-2015) do qual resultou um vasto arquivo
            audiovisual e o documentário "Independência". Actualmente é membro do Conselho Científico do Projecto CROME (CES,
            Coimbra) e integra o Projecto internacional LEGALPL (Pluralismo Jurídico no Império Português, secs. XVIII-XX). Tem
            diversos artigos em revistas especializadas e participação em livros. Áreas de maior  interesse: dinâmicas das
            sociedades sob domínio colonial; colonialismo europeu em África (secs. XIX-XX); memória e história das lutas
            anticoloniais.</p>

          <p><a @click="showElement(MCN)">Publicações relevantes</a></p>

        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/LO.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Luís Oliveira</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">Luís Pedroso de Lima Cabral de Oliveira (n. 1978) é licenciado em Direito pela Universidade de Coimbra,
            diplomado em estudios avanzados de tercer ciclo em História pela Universidad Pablo de Olavide, de Sevilha, e doutor em
            Direito pela Universidade Nova de Lisboa, onde defendeu a tese A consagração dos naturais: direito(s) e elites naturais
            católicas em Goa (1780-1880). Professor adjunto do departamento de ciências jurídicas do Instituto Politécnico de
            Leiria, coordenador da licenciatura em Administração Pública na mesma instituição e investigador do Centro de
            Investigação Direito e Sociedade (CEDIS) da Faculdade de Direito da Universidade Nova de Lisboa. Desenvolve o seu
            trabalho científico (através de publicações, conferências e participação em projetos nacionais e internacionais) nas
            áreas do direito colonial, da história do direito e da ciência política, interessando-se particularmente pelas
            questões relativas ao Estado da Índia.</p>

          <p><a @click="showElement(LO)">Publicações relevantes</a></p>

        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/NC.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Nuno Camarinhas</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">Investigador pós-doutoramento do CEDIS, da Faculdade de Direito da Universidade Nova de Lisboa. Doutorado
            em História pela École des Hautes Études en Sciences Sociales (Paris, França, 2007), Mestre em Ciências Sociais pelo
            Instituto de Ciências Sociais da Universidade de Lisboa (2000), tem trabalhado sobre a magistratura portuguesa e a
            administração da justiça em contextos coloniais. Tem publicado vários artigos e capítulos de livros, especialmente
            sobre o aparelho judicial português no ultramar, sobre o controlo da magistratura, sobre redes burocráticas no
            império português, sobre bibliotecas de magistrados ou sobre aplicação de métodos de análise de redes ao estudo da
            circulação de pessoas e livros no caso português. Entre os seus livros, destacam-se Juízes e administração da
            Justiça. Portugal e o seu império colonial, sécs. XVII-XVIII (Lisboa, Gulbenkian/FCT, 2010), que teve também uma edição
            francesa (Paris, L’Harmattan, 2012), e a edição crítica do Memorial de Ministros (2 vols.), da autoria de Fr. Luís de
            São Bento e Fr. António Soares (Lisboa / São Paulo, Biblioteca Nacional de Portugal / COPEDEM, 2017).</p>

          <p><a @click="showElement(NC)">Publicações relevantes</a></p>

        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/PC.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Pedro Cardim</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">Pedro Cardim, especialista em História da Época Moderna, História Política, História da Cultura Política,
            História do Direito e História do Brasil. É investigador do CHAM – Centro de Humanidades da Universidade Nova de Lisboa
            e da Universidade dos Açores; foi professor convidado das seguintes instituições: New York University; École des
            Hautes Études en Sciences Sociales; Universidad pablo de Olavide; Universidad Autónoma de Madrid; Université de
            Toulouse – Jean Jaurès</p>

          <p><a @click="showElement(PC)">Publicações relevantes</a></p>

        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/RR.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Ricardo Roque</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">Ricardo Roque é Investigador Auxiliar no Instituto de Ciências Sociais da Universidade de Lisboa.
            Doutorou-se em História pela Universidade de Cambridge (2007), após estudar sociologia e sociologia histórica na
            Universidade Nova de Lisboa (Lic., Mestr.). Antes de integrar o quadro de investigadores do ICS-ULisboa, foi
            professor na Universidade dos Açores (1999-2008) e Postdoctoral Research Fellow (2012-13) na Universidade de Sidney,
            à qual continua afiliado como Honorary Associate no Departamento de História. No ICS, é o atual coordenador do Grupo de
            Investigação <a href='http://gi-imperios.org/blog/' target='_blank'>Impérios, Colonialismo e Sociedades Pós-coloniais</a>
            e ensina nos programas doutorais em Antropologia e História.</p>

          <p class="block">Trabalha sobre história e antropologia das ciências humanas, do colonialismo, e das relações interculturais nos
            espaços de expressão portuguesa, desde 1800 até ao século XX. O seu primeiro livro (<i>Antropologia e Império</i>, 2001 –
            Menção Honrosa Prémio Sedas Nunes 2002) analisou as origens coloniais da antropologia física em Goa e Portugal no
            final do século XIX. O seu segundo livro (<i>Headhunting and Colonialism</i>, 2010) examinou as relações entre violência
            colonial, ‘caça de cabeças’, e circulação de crânios humanos para museus científicos, entre Timor-Leste, Portugal,
            e a Europa.</p>

          <p class="block">Nos últimos anos a sua pesquisa tem incidido sobre a etnografia histórica do mimetismo colonial e os estudos
            comparativos da ciência racial e suas colecções biológicas. Resultados recentes destas pesquisas incluem o livro
            <i>Luso-tropicalism and Its Discontents</i> (co-organizado com Warwick Anderson e Ricardo Ventura Santos), uma história
            crítica comparada das teses luso-tropicalistas; e <i>Crossing Histories and Ethnographies</i> (co-organizado com Elizabeth
            Traube), uma antropologia histórica das historicidades coloniais em Timor-Leste, ambos a publicar pela editora Berghahn
            em 2019. É actualmente o Investigador Responsável do projecto de investigação, <i>Arquivos Coloniais Nativos:
              Micro-histórias e comparações</i>, em curso no ICS e financiado pela Fundação para a Ciência e Tecnologia (FCT).</p>

          <p><a @click="showElement(RR)">Publicações relevantes</a></p>

        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/SBC.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Samory Badona Monteiro</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">Samory é membro do CEDIS desde 2017. Ele é doutorando em direito internacional público na Universidade
            Europeia Viadrina de Frankfurt Oder (Alemanha) e jurista associado no Gabinete do Conselho Jurídico da União Africana.
            Mestre em Direito Europeu e Transnacional pela Universidade de Trento (Itália) e LL.M. em Direito Internacional dos
            Direitos Humanos e Direito Humanitário pela Universidade Europeia Viadrina. Samory fez o estágio de advocacia por
            dois anos na Itália e, posteriormente, trabalhou como estagiário jurídico no Tribunal de Justiça da CEDEAO e no
            Instituto Ludwig Boltzmann de Direitos Humanos em Viena (Áustria). </p>

          <p><a @click="showElement(SB)">Publicações relevantes</a></p>

        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/YP.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Yamê Paiva</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">Yamê Paiva é licenciada e mestre em História pela Universidade Federal da Paraíba (UFPB), e presentemente
            realiza o doutoramento em História pela Universidade NOVA de Lisboa (UNL). Desde o mestrado sua investigação está
            voltada para a temática da administração da justiça no Brasil durante os séculos XVII e XVIII, com especificidade nas
            Capitanias do Norte do Estado do Brasil, através da qual busca construir uma visão panorâmica das justiças oficial e
            não-oficial. Alguns dos seus principais pontos de interesse são a composição e funcionamento do aparelho judicial
            ultramarino em diferentes comarcas, as formas extrajudiciais de resolução de conflitos, o acesso à justiça régia por
            parte dos habitantes locais e dos nativos e como os juízes régios lidavam com as questões postas pela distância no
            interior das fronteiras do Império português. Atualmente é bolseira do projeto Pluralismo Jurídico no Império
            Português (séculos XVIII-XX).</p>

          <p><a @click="showElement(YP)">Publicações relevantes</a></p>

        </div>
      </div>

      <h2 class="is-title is-size-4 is-split">Consultores</h2>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/C-LB.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Lauren Benton</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">Lauren Benton detêm a Cátedra Nelson O. Tyrone em História e é Professora de Direito na Vanderbilt
            University. Em 2019, foi eleita Presidente da American Society for Legal History e recebeu o prémio da Toynbee
            Foundation que distingue contribuições significativas no domínio da História Global. Antes de se juntar à Faculdade
            da Vanderbilt University, Benton era a titular da Cátedra Julius Silver, e Professora de História associada à New York
            University. Lauren Benton começou a sua carreira académica enquanto antropóloga económica, pesquisando as condições
            laborais na economia informal e industrial em Espanha e na América Latina. Tem publicado regularmente obras marcantes
            de História comparada dos impérios e do direito internacional.</p>

          <p><a @click="showElement(CLB)">Publicações relevantes</a></p>

        </div>
      </div>

      <div class="columns is-vcentered is-team-element">
        <div class="column is-one-third">
          <img src="@/assets/C-TD.png">
          <p class="has-text-centered" style='font-size: 110%'><b>Thomas Duve</b></p>
          <p class="has-text-centered">(Investigadora)</p>
        </div>
        <div class="column">
          <p class="block">Thomas Duve é Professor de História do Direito Comparativa, na Goethe University de Frankfurt, desde
            2010, e o atual Diretor do <i>Max-Planck-Institute for European Legal History</i> (2010 – ). Membro da <i>Max-Planck
              Society</i>, da <i>Academia Europaea e da Akademie der Wissenschaften und der Literatur Mainz</i>, é o investigador
            principal do <i>Cluster of Excellence “The Formation of Normative Orders”</i> na <i>Goethe University Frankfurt</i>.
            Editor do jornal científico <i>Rechtsgeschichte-Legal History</i> e coeditor do <i>forum historiae iuris</i>, e do
            <i>Anuario de Historia de America Latina</i>, Duve coordena ainda a série editorial do <i>Max-Planck-Institute for
              European Legal History</i>, intitulada <i>Beiträge zur Rechtsgeschichte des 20. Jahrhunderts</i>, e coedita as séries
            <i>Politische Philosophie und Rechtstheorie des Mittelalters und der Neuzeit</i> (PPR) e <i>methodica – Einführungen in
              die rechtshistorische Forschung</i>. Thomas Duve dedica-se a investigar a História Legal dos espaços imperiais das
            monarquias Ibéricas, durante o início do período moderno, e a produzir histórias do Direito Europeu a partir de uma
            perspetiva ampla, apoiada pelas metodologias da História Global. Interessa-se especialmente pela História do Direito
            Canónico e pela Teologia Moral, tal como estas disciplinas foram abordadas pela Escola de Salamanca, e pela
            Lei Internacional.</p>

          <p><a @click="showElement(CTD)">Publicações relevantes</a></p>

        </div>
      </div>


    </div>
    <team-work v-if="showWork" @close="showWork = false" :modal-content="teamElement"></team-work>
  </div>
</template>

<script>
import TeamWork from "../components/partial/TeamWork";
export default {
  name: "Team",
  components: {TeamWork},
  data:()=>({
    CNS: '<p class="block">SILVA, Cristina Nogueira da, “<a href=\'https://dialnet.unirioja.es/servlet/articulo?codigo=6920934\' target=\'_blank\'>A \n' +
        'dimensão imperial do espaço jurídico português. Formas de imaginar a pluralidade nos espaços ultramarinos, séculos \n' +
        'XIX e XX</a>”, <i>Rechtsgeschichte - Legal History, Journal of the Max Planck Institute for European Legal History</i>, \n' +
        'nº 23, 2015, pp. 187-207.</p>\n' +
        '\n' +
        '<p class="block">SILVA, Cristina Nogueira da, “<a href=\\"$CNSBioLink1\\" target=\'_blank\'>Universalism, Legal pluralism and \n' +
        'Citizenship: Portuguese Imperial Policies in citizenship and law in the nineteenth century</a>”, in Clifford Ando, \n' +
        'org., <i>Citizenship and Empire in Europe, 200-1900. The Antonine Constitution after 1800 years</i>, Stuttgart, Franz \n' +
        'Steiner Verlag, 2016, pp. 199-221.</p>\n' +
        '\n' +
        '<p class="block">SILVA, Cristina Nogueira da, “<a href=\'https://dialnet.unirioja.es/servlet/articulo?codigo=5189286\' target=\'_blank\'>«Missão \n' +
        'civilizacional» e Codificação  de «usos e costumes» na doutrina colonial portuguesa (séculos XIX-XX)</a>”, <i>Quaderni \n' +
        'Fiorentini per la Storia del Pensiero Giuridico Moderno</i>, nºs 33-34, t. II, 2004-2005, pp. 899-921.</p>\n' +
        '\n' +
        '<p class="block">SILVA, Cristina Nogueira da, “<a href=\\"$CNSBioLink2\\" target=\'_blank\'>Uma justiça «liberal» para o Ultramar? \n' +
        'Direito e Organização Judiciária nas Províncias Ultramarinas Portuguesas do século XIX</a>”, <i>Revista do Ministério \n' +
        'Público</i>, nº 103, 2006, pp. 165-200.</p>',
    FT:'<p class="block">Thomaz, Fernanda do Nascimento, <a href=\'http://www.historia.uff.br/stricto/td/1419.pdf\' target=\'_blank\'>\n' +
        'O casaco que despe pelas costas: formação da justiça colonial e a \n' +
        '(re) ação dos africanos no norte de Moçambique</a>, Tese de Doutoramento, Universidade Federal Fluminense, 2012.</p>\n' +
        '\n' +
        '<p class="block">Thomaz, Fernanda do Nascimento, “<a href=\'http://www.scielo.br/scielo.php?script=sci_arttext&pid=S0103-21862012000200003\' target=\'_blank\'>Disciplinar \n' +
        'o \\"indígena\\" com pena de trabalho: políticas coloniais portuguesas em \n' +
        'Moçambique</a>”, <i>Estudos Históricos (Rio de Janeiro)</i>, vol. 25, nº 50 (2012), pp. 313-330.</p>\n' +
        '\n' +
        '<p class="block">Thomaz, Fernanda do Nascimento, “<a href=\'http://www.ucs.br/etc/revistas/index.php/metis/article/viewFile/1069/1047\' target=\'_blank\'>Que \n' +
        'justiça deve-se aplicar? Dois tribunais coloniais privativos Dois tribunais \n' +
        'coloniais privativos para os “africanos” em Moçambique</a>”, <i>MÉTIS: história e cultura</i>, vol. 10, nº 19 (2011), pp. 81-98.</p>\n' +
        '\n' +
        '<p class="block">Thomaz, Fernanda do Nascimento, “<a href=\'https://www.outrostempos.uema.br/OJS/index.php/outros_tempos_uema/article/view/462/0\' target=\'_blank\'>Testemunhos \n' +
        'de violência num registro judicial durante a ocupação colonial no \n' +
        'norte de Moçambique</a>”, <i>Outros Tempos</i>, vol. 12, nº 19 (2015), pp 236-247. </p>',
    JF:'<p class="block">FIGUEIREDO, João, ‘<a href=\'https://periodicos.ufrn.br/mneme/article/view/1026/949\' target=\'_blank\'>Feitiçaria \n' +
        'na Angola oitocentista: razões por detrás de uma suposta maior tolerância administrativa face a crenças locais</a>’, \n' +
        '<i>Mneme – Revista de Humanidades</i>, 12, 29 (2011), 21-51.\n' +
        '<p class="block">FIGUEIREDO, João, “<a href=\'https://journals.openedition.org/cea/1842\' target=\'_blank\'>A \n' +
        'Questão das “Ouvidas”, ou a Disputa entre Autoridades Civis e Militares pelo \n' +
        'Julgamento de “Causas Gentílicas</a>” na Angola de Meados do Século XIX”, <i>Cadernos de Estudos Africanos</i>, nº30 (2015), pp. 81-104.</p>\n' +
        '<p class="block">FIGUEIREDO, João, <a href=\'https://estudogeral.sib.uc.pt/handle/10316/29906\' target=\'_blank\'><i>Política Escravatura \n' +
        'e Feitiçaria em Angola (séculos XVIII-XIX)</i></a>, tese de doutoramento policopiada, Coimbra, Faculdade de Letras da \n' +
        'Universidade de Coimbra, 2016.</p>\n' +
        '<p class="block">FIGUEIREDO, João, “<a href=\'https://brill.com/view/journals/ssm/30/3-4/article-p366_8.xml\' target=\'_blank\'>Heimlich/unheimlich: \n' +
        'Outlining the Influence of Spiritan Worldviews in the Work of Angolan \n' +
        'Pioneer Photographers José Moraes and Elmano Costa</a>”, <i>Social Sciences and missions</i>, vol. 30, nº 3-4 (2017), \n' +
        'pp. 366-387.</p>',
    LO:'<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, ‘In the Land of Advogadomania: The Representation of the Goan \n' +
        'Provisionário in José da Silva Coelho’s Contos Regionais’, <i>in</i> CASTRO, P. M. (coord.), <i><a href=\'https://www.uwp.co.uk/book/colonial-and-post-colonial-goan-literature-in-portuguese-paperback/\' target=\'_blank\'>Colonial \n' +
        'and Post-Colonial Goan Literature in Portuguese</a></i>, Cardiff, University of Wales Press, 2019, 107-123.</p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, <a href=\'https://e-archivo.uc3m.es/bitstream/handle/10016/27751/estudios_hd66_2018.pdf?sequence=4&isAllowed=y\' target=\'_blank\'>‘Direito, \n' +
        'política e sociedade: as Novas Conquistas de Goa durante o Perismo. Os contributos de Nery Xavier e Cláudio Lagrange’</a>, \n' +
        'in VARELA, L. B. (coord.) e SASTRE, M. J. S. (coord.), <i>Estudios Luso-Hispanos de Historia del Derecho</i>, Madrid, \n' +
        'Universidad Carlos III de Madrid, 2018, 271-303.</p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, <a href=\'https://www.academia.edu/36222031/Magistrados_letrados_ou_provision%C3%A1rios_O_caso_de_Andr%C3%A9_Francisco_de_Bragan%C3%A7a_Goa_1832-1836_\' target=\'_blank\'>‘Magistrados \n' +
        'letrados ou provisionários? O caso de André Francisco de Bragança (Goa, 1832-1836)’</a>, <i>Teoria e História do \n' +
        'Direito</i>, 1, 1, 2016, 95-108.</p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, <a href=\'https://revistas.uam.es/revistajuridica/article/view/6482/9576\' target=\'_blank\'>‘Em \n' +
        'Lisboa, entre Espanha e a Índia: a conservatória estrangeira da nação espanhola e as penas de degredo para Goa (finais \n' +
        'do século XVIII)’</a>, <i>Revista Jurídica da Universidad Autónoma de Madrid</i>, 33, 2016, 41-59. </p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, <a href=\'http://www.revistas.usp.br/viaatlantica/article/view/118313\' target=\'_blank\'>‘Ler \n' +
        'direito: o testamento de Olivier-Simon Le Bon (Goa, 1780)’</a>, <i>Via Atlântica</i>, 30, 2016, 175-199. </p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, <a href=\'https://digitalis.uc.pt/pt-pt/artigo/de_coimbra_goa_no_encal%C3%A7o_de_bernardo_de_almeida_torres_f%C3%ADsico_mor_do_estado_da_%C3%ADndia\' target=\'_blank\'>‘De \n' +
        'Coimbra a Goa: no encalço de Bernardo de Almeida Torres, físico-mor do Estado da Índia’</a>, <i>Revista Portuguesa de \n' +
        'História</i>, 46, 2015, 145-162.</p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, <a href=\'https://novaresearch.unl.pt/en/publications/quem-sabe-o-que-%C3%A9-um-advogado-a-resposta-de-lu%C3%ADs-manuel-j%C3%BAlio-fre\' target=\'_blank\'>‘Quem \n' +
        'sabe o que é um advogado? – A resposta de Luís Manuel Júlio Frederico Gonçalves às tentativas de reforma dos \n' +
        'provisionários goeses em 1869’</a>, <i>Jahrbuch für Geschichte Lateinamerikas - Anuario de Historia de América Latina</i>, \n' +
        '52, 2015, 207-230. </p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, <a href=\'https://novaresearch.unl.pt/en/publications/contrepoint-existe-t-il-un-mod%C3%A8le-ib%C3%A9rique-les-fonctionnaires-de-\' target=\'_blank\'>‘Contrepoint. \n' +
        'Existe-t-il un modèle ibérique? Les fonctionnaires de l’empire portugais au XIXe siècle’</a>, <i>in</i> LUIS, J.-P., \n' +
        '<i>L\'État das ses colonies : les administrateurs de l\'Empire espagnol au XIXe siècle</i>, Madrid, Casa de Velázquez, \n' +
        '2015, 253-268. </p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, <a href=\'http://cec.letras.ulisboa.pt/en/act/act-27-goa-portuguesa-e-pos-colonial-literaturacultura-e-sociedade/\' target=\'_blank\'>‘Breda \n' +
        'e Coimbra: que trajetos no direito?’</a>, <i>in</i> MACHADO, E. V. (org.) e BRAGA, D. D. (org.), <i>Goa Portuguesa e \n' +
        'pós-colonial: literatura, cultura e sociedade</i>, V. N. de Famalicão, Edições Húmus, 2014, 219-236.</p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, ‘Emancipate your colonies! Reacções de Bernardo Peres da Silva ao \n' +
        'anticolonialismo de Bentham’, <i>in</i> <i><a href=\'http://www.uceditora.ucp.pt/site/custom/template/ucptpl_uce.asp?SSPAGEID=1278&lang=1&artigoID=1381\' target=\'_blank\'>Estudos \n' +
        'dedicados ao Professor Doutor Nuno Espinosa Gomes da Silva</a></i>, vol. I, Lisboa, Universidade Católica Editora, \n' +
        '2013, 497-531.</p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, ‘Magistrados <i>reinóis</i> no espaço ultramarino: o caso particular da \n' +
        'Goa setecentista’, <i>in</i> GOUVEIA, M. F. (ed.), BRITO, M. H. (ed.) e GUEDES, A. M. (ed.), <i>Estudos em Homenagem ao Prof. \n' +
        'Doutor José Lebre de Freitas</i>, vol. II, Coimbra, Coimbra Editora, 2013, 1105-1174.</p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, ‘Goa oitocentista, terra de letras e terra de juristas. O caso dos \n' +
        'advogados provisionários’, <i>in</i> MATOS, A. T. de (coord.), CUNHA, J. T. e (coord.), <i>Goa: Passado e Presente</i>, \n' +
        'Lisboa, Centro de Estudos dos Povos e Culturas de Expressão Portuguesa, 2012, 543-566. </p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, <a href=\'http://www.revistas.usp.br/viaatlantica/article/view/50799/54900\' target=\'_blank\'>‘Direito, \n' +
        'literatura e prática judicial na Goa de outrora: o caso dos advogados provisionários (2ª Parte)’</a>, <i>Atlântica</i>, \n' +
        '20, 2011, 187-198. </p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, <a href=\'https://dialnet.unirioja.es/servlet/articulo?codigo=2877124\' target=\'_blank\'>‘Direito, \n' +
        'sociedade e política: Manuel Duarte Leitão em Goa (1817-1821)’</a>, <i>E-Legal History Review</i>, 7, 2009. </p>\n' +
        '<p class="block">OLIVEIRA, Luís Pedroso de Lima Cabral de, ‘Do Reformismo Jurídico Pombalino em Goa’, <i><a href=\'https://www.fd.uc.pt/bfd/publicacoes_bfduc2003.html\' target=\'_blank\'>Boletim \n' +
        'da Faculdade de Direito</a></i>, 79, 2003, 627-679. </p>',
    MCN:'<p class="block">Neto, Maria da Conceição, \'Colonial Incarceration and Selective Memories: What Is Remembered? Who Is \n' +
        'Forgotten? The Case of Peasant Women Deported to São Nicolau (Angola, 1969)\', <i>The Journal of Imperial and \n' +
        'Commonwealth History</i>, vol. 47, n. 2, 325-342.</p>\n' +
        '<p class="block">Neto, Maria da Conceição, \'Uma igreja ambivalente: os católicos angolanos entre a \\"portugalidade\\" e a subversão da \n' +
        'ordem colonial\', <i>in</i> Gonçalves, Leandro Pereira; Rezola,  Maria  Inácia  (orgs.).  <i>Igrejas e ditaduras no \n' +
        'mundo lusófono</i>. Lisboa: Imprensa de Ciências Sociais, pp. 307-338.</p>\n' +
        '<p class="block">Neto, Maria da Conceição, \'De Escravos a \'Serviçais\', de \'Serviçais\' a \'Contratados\': Omissões, perceções e \n' +
        'equívocos na história do trabalho africano na Angola colonial\'. Cadernos de Estudos Africanos (Lisboa), \n' +
        '33: 107-129.</p>\n' +
        '<p class="block">Neto, Maria da Conceição, \'The Colonial State and its Non-citizens: \'Native Courts\' and Judicial Duality in Angola\'. \n' +
        '<i>Portuguese Studies Review</i>, 25 (1): 235-253.</p>',
    NC:'<p class="block">Camarinhas, Nuno, “<a href=\'https://www.ingentaconnect.com/content/intellect/pjss/2013/00000012/00000002/art00005\' target=\'_blank\'>Justice \n' +
        'administration in early modern Portugal: kingdom and empire in a bureaucratic \n' +
        'continuum</a>”. <i>Portuguese Journal of Social Science</i>, vol.12, nº2, pp. 179-193.</p>\n' +
        '<p class="block">Camarinhas, Nuno, “<a href=\'http://analisesocial.ics.ul.pt/documentos/n226a06.pdf\' target=\'_blank\'>Lugares \n' +
        'ultramarinos: a construção do aparelho judicial no ultramar português da época moderna</a>”. \n' +
        '<i>Análise Social</i>, vol. 53, nº 226, pp.136-160.</p>',
    PC:'<p class="block">CARDIM, Pedro, \'<a href=\\"$PCBioLink1\\" target=\'_blank\'>Os Povos Indígenas, a dominação colonial e as \n' +
        'instâncias da justiça na América portuguesa e espanhola</a>\' <i>in</i> DOMINGUES, Ângela <i>et al</i>. (orgs.), \n' +
        '<i>Os Indígenas e as Justiças no Mundo Ibero-Americano (Sécs. XVI-XIX)</i>, Lisboa, Centro de História da Universidade \n' +
        'de Lisboa, 2020, pp. 29-84.</p>\n' +
        '<p class="block">CARDIM, Pedro, \'The political constitution of the Iberian monarchies\' <i>in</i> BOUZA, F., P. Cardim & A. Feros \n' +
        '(orgs.), <i>The Iberian World, 1450-1820</i>, Londres, Routledge, 2019, pp. 34-61 (co-autoria com Antonio Feros e \n' +
        'Gaetano Sabatini).</p>\n' +
        '<p class="block">CARDIM, Pedro, ‘<a href=\'https://dialnet.unirioja.es/descarga/articulo/5906113.pdf\' target=\'_blank\'>Political \n' +
        'Status and Identity: Debating the Status of American Territories across the \n' +
        'Sixteenth and Seventeenth Century Iberian World</a>’, <i>Rechtsgeschichte. Journal of the Max Planck Institute for European \n' +
        'Legal History</i>, 24 (2016), 101-116.</p>\n' +
        '<p class="block">CARDIM, Pedro, ‘<a href=\'https://dialnet.unirioja.es/servlet/autor?codigo=120238\' target=\'_blank\'>Política y \n' +
        'pluralismo jurisdiccional en los territorios castellanos y portugueses del Atlántico y de Asia (siglo XVI)</a>’, in \n' +
        'BERNAL, António Miguel (org.), <i>Modernidad de España. Apertura europea e integración atlântica</i>, Madrid, Marcial \n' +
        'Pons, 2017, 463-488.</a>”.</p>\n' +
        '<p class="block">CARDIM, Pedro e BALTAZAR, Miguel, ‘A difusão \n' +
        'da legislação régia (1621-1808)’ in  FRAGOSO, João (org.) e MONTEIRO, Nuno Gonçalo (org.), <a href=\'https://repositorio.ul.pt/handle/10451/28913\' target=\'_blank\'><i>Um \n' +
        'reino e suas repúblicas no Atlântico. Comunicações políticas entre Portugal, Brasil e Angola nos séculos XVII e \n' +
        'XVIII</i></a>, Rio de Janeiro, Record, 161-208.</p>\n' +
        '<p class="block">CARDIM, Pedro, BICALHO, Maria Fernanda e RODRIGUES, José Damião, ‘Cortes, juntas e procuradores’, in  FRAGOSO, \n' +
        'João (org.) e MONTEIRO, Nuno Gonçalo (org.), <a href=\'https://repositorio.ul.pt/handle/10451/28913\' target=\'_blank\'><i>Um \n' +
        'reino e suas repúblicas no Atlântico. Comunicações políticas entre Portugal, Brasil e Angola nos séculos XVII e \n' +
        'XVIII</i></a>, Rio de Janeiro, Record, 101-136.</p>\n' +
        '<p class="block">CARDIM, Pedro e KRAUSE, Thiago, ‘<a href=\'https://repositorio.ul.pt/handle/10451/26177?locale=en\' target=\'_blank\'>A \n' +
        'comunicação entre a câmara de Salvador e os seus procuradores em Lisboa durante a segunda metade do século XVII</a>’ in \n' +
        'SOUZA, Evergton Sales (org.), SILVA, Hugo Ribeiro da (org.) e MARQUES, Guida (org.), <i>Salvador da Bahia: retratos de \n' +
        'uma cidade atlântica</i>, Salvador da Bahia, EDUFBA-CHAM/UNL, 2016, 59-110.</p>\n' +
        '<p class="block">CARDIM, Pedro e KRAUSE, Thiago, ‘<a href=\'https://www.oxfordbibliographies.com/view/document/obo-9780199730414/obo-9780199730414-0280.xml\' target=\'_blank\'>Colonial \n' +
        'Governance in the Atlantic World</a>’, in BURNARD, Trevor (ed.), <i>Oxford Bibliographies in Atlantic History</i>, Oxford, \n' +
        'Oxford University Press, 2018, sp.</p>\n' +
        '<p class="block">CARDIM, Pedro e MIRANDA, Susana Münch, ‘<a href=\'https://novaresearch.unl.pt/en/publications/a-incorporação-de-territórios-e-o-estatuto-político-do-espaço-ult\' target=\'_blank\'>A \n' +
        'incorporação de territórios e o estatuto político do espaço ultramarino</a>’, in FRAGOSO, João (org.) e GOUVÊA, Maria \n' +
        'de Fátima (org.), <i>O Brasil Colonial. A Dinâmica dos Pactos e Conflitos entre os Impérios</i>, Rio de Janeiro, \n' +
        'Civilização, 2015.</p>',
    YP:'<p class="block">Paiva, Yamê, “<a href=\'https://dialnet.unirioja.es/servlet/articulo?codigo=5630643\' target=\'_blank\'>Ouvidores \n' +
        'e administração da justiça no Brasil colonial. O caso da comarca da Capitania da Paraíba (c.a. 1687-c.a. 1799)</a>”, \n' +
        '<i>Revista Jurídica Universidad Autónoma de Madrid</i>, vol. 33, nº1 (2016), pp. 79-95.</p>\n' +
        '<p class="block">Paiva, Yamê, “<a href=\'https://journals.openedition.org/nuevomundo/71578\' target=\'_blank\'>Os regimentos dos \n' +
        'ouvidores de comarca na América portuguesa, séculos XVII e XVIII: esboço de análise</a>”, \n' +
        '<i>Nuevo Mundo Mundos Nuevos</i> [Online] (2017).</p>\n' +
        '<p class="block">Paiva, Yamê, “Antônio Soares Brederode: Justiça e transgressão na capitania da Paraíba em finais do século XVIII”, \n' +
        '<i>In</i> CHAVES JR, José Inaldo [et al] (Org.), <i>Colonialidades: governos, gentes e territórios na América Ibérica \n' +
        '(séculos XVII-XIX)</i>, Curitiba, Editora Prismas, 2016, pp. 45-62.</p>',
    SB:'<p class="block">MONTEIRO, Samory Badona, <a href=\'http://biblionet.fd.unl.pt/Opac/Pages/Search/Results.aspx?Database=10406_GERAL&SearchText=ASS=\\"Guiné\\"\' target=\'_blank\'><i>L\'equità \n' +
        'e la riparazione nel diritto penale della Guinea Bissau</i></a>, tese de mestrado policopiada, Trento, Universitá Degli \n' +
        'Studi di Trento, Facoltà di Giurusprudenza, 2012. </p>\n' +
        '<p class="block">MONTEIRO, Samory Badona, ‘<a href=\'http://cedis.fd.unl.pt/blog/project/revista-do-direito-de-lingua-portuguesa-n-o-8/\' target=\'_blank\'>A \n' +
        'actualidade do direito tradicional no sistema jurídico da Guiné-Bissau: perspectivas \n' +
        'sinérgicas à luz da reparação penal</a>’, <i>Revista do Direito de Língua portuguesa</i>, 8 (2016), 159-197.</p>',
    ABX:'<p class="block">E-mail: <a href=\\"mailto:angela.xavier@ics.ul.pt\\">angela.xavier@ics.ul.pt</a><br />\n' +
        '            Instituto de Ciências Sociais da Universidade de Lisboa<br />\n' +
        '            Av. Prof. Aníbal Bethencourt, 9<br />\n' +
        '            1600-089 Lisboa<br />\n' +
        '            Portugal<br />\n' +
        '</p>',
    RR:'<p class="block">“<a href=\'https://repositorio.ul.pt/handle/10451/34409\' target=\'_blank\'>Dances with heads: parasitic \n' +
        'mimesis and the government of savagery in colonial East Timor</a>”, <i>Social Analysis</i>, 62: 2 (2018), pp. 28-50.</p>\n' +
        '<p class="block">“<a href=\'https://repositorio.ul.pt/handle/10451/15754\' target=\'_blank\'>Mimetic governmentality and the \n' +
        'administration of colonial justice in East Timor, ca. 1860-1910</a>”, <i>Comparative Studies in Society and History</i>, \n' +
        '51: 1 (2015), pp. 67-97.</p>\n' +
        '<p class="block">“<a href=\'http://www.scielo.mec.pt/scielo.php?script=sci_abstract&pid=S0873-65612014000100008&lng=pt&nrm=iso\' target=\'_blank\'>‘Seria \n' +
        'preciso que a selvageria se me pegasse’: Afonso de Castro e a festa das cabeças em Timor colonial</a>”, <i>Etnográfica</i>, \n' +
        '18: 1 (2014), pp. 159-184.</p>\n' +
        '<p class="block">“<a href=\'http://www.scielo.br/scielo.php?script=sci_arttext&pid=S0104-93132012000300006\' target=\'_blank\'>A voz dos \n' +
        'bandos: colectivos de justiça e ritos da palavra portuguesa em TimorLeste colonial</a>”, <i>Mana – Estudos de \n' +
        'Antropologia Social</i>, 18: 3 (2012), pp. 563-594.</p>',
    CLB:'<p class="block">BENTON, Lauren (ed.), CLULOW, Adam (ed.) e ATTWOOD, Bain (ed.), \n' +
        '<a href=\'https://books.google.pt/books/about/Protection_and_Empire.html?id=VQ44DwAAQBAJ&printsec=frontcover&source=kp_read_button&redir_esc=y#v=onepage&q&f=false\' target=\'_blank\'><i>Protection \n' +
        'and Empire: A Global History</i></a>, Cambridge, Cambridge University Press, 2018.</p>\n' +
        '<p class="block">BENTON, Lauren e FORD, Lisa, <a href=\'https://books.google.pt/books/about/Rage_for_Order.html?id=hMcZDQAAQBAJ&source=kp_book_description&redir_esc=y\' target=\'_blank\'><i>Rage \n' +
        'for Order: The British Empire and the Origins of International Law, 1800-1850</i></a>, Cambridge MA, Harvard University Press, 2016.</p>\n' +
        '<p class="block">BENTON, Lauren (ed.) e ROSS, Richard J. (ed.), <a href=\'https://books.google.pt/books/about/Legal_Pluralism_and_Empires_1500_1850.html?id=D4uKgK5IQB0C&printsec=frontcover&source=kp_read_button&redir_esc=y#v=onepage&q&f=false\' target=\'_blank\'><i>Legal \n' +
        'Pluralism and Empires, 1500-1850</i></a>, Nova Iorque, New York University Press, 2013.</p>\n' +
        '<p class="block">BENTON, Lauren, <a href=\'https://books.google.pt/books/about/A_Search_for_Sovereignty.html?id=i15gAgAAQBAJ&printsec=frontcover&source=kp_read_button&redir_esc=y#v=onepage&q&f=false\' target=\'_blank\'><i>A \n' +
        'Search for Sovereignty: Law and Geography in European Empires, 1400-1900</i></a>, Cambridge, Cambridge University Press, 2010. </p>\n' +
        '<p class="block">BENTON, Lauren, <a href=\'https://books.google.pt/books/about/Law_and_Colonial_Cultures.html?id=rZtjR9JnwYwC&printsec=frontcover&source=kp_read_button&redir_esc=y#v=onepage&q&f=false\' target=\'_blank\'><i>Law \n' +
        'and Colonial Cultures: Legal Regimes in World History, 1400-1900</i></a>, Cambridge, Cambridge University Press, 2002.</p>',
    CTD:'<p class="block">DUVE, Thomas, ‘<a href=\'http://rg.rg.mpg.de/en/article_id/1094\' target=\'_blank\'>Was ist \n' +
        '„Multinormativität“? – Einführende Bemerkungen</a>’, <i>Rechtsgeschichte – Legal History Rg</i> 25 (2017), 88 – 101.</p>\n' +
        '\n' +
        '<p class="block">DUVE, Thomas (ed.), <a href=\'https://www.rg.mpg.de/publications/entanglements_in_legal_history\' target=\'_blank\'><i>Entanglements \n' +
        'in Legal History: Conceptual Approaches</i></a>, Frankfurt am Main, Max Planck Institute for European Legal History, \n' +
        '2014.</p>\n' +
        '\n' +
        '<p class="block">DUVE, Thomas (ed.), DANWERTH, Otto (ed.) e ALBANI, Benedetta (ed.), <a href=\'https://www.rg.mpg.de/publikationen/gplh-5\' target=\'_blank\'><i>Normatividades \n' +
        'e instituciones eclesiásticas en el Virreinato del Perú, siglos XVI-XIX</i></a>, Frankfurt am Main, Max Planck \n' +
        'Institute for European Legal History, 2019.</p>\n' +
        '\n' +
        '<p class="block">DUVE, Thomas (ed.) e PIHLAJAMÄKI, Heikki (ed.), <a href=\'https://www.rg.mpg.de/publikationen/gplh_3\' target=\'_blank\'><i>New \n' +
        'Horizons in Spanish Colonial Law. Contributions to Transnational Early Modern Legal History</i></a>, Max Planck \n' +
        'Institute for European Legal History, 2015.</p>',
    //CONTROL
    showWork: false,
    teamElement: '',
  }),
  methods:{
    showElement(element){
      this.teamElement = element
      this.showWork = true;
    }
  }
}
</script>

<style scoped>
.is-title{
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  color: #330000;
}

.is-team{
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.is-team-element{
  padding-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px dotted #636363;
}

.is-split{
  padding-top: 160px;
}
</style>