<template>
<div>
  <h2 class="is-title is-size-4" v-if="term !== ''">Termo Pesquisado: {{term}}</h2>
  <div class="columns" v-for="n in maxDocument()" :key="n">
    <div class="column">
      <document-result :document="filterDocuments()[n-1]" :term="term" :search-mode="searchMode"
                       @view="showDocument" @goto="showPage"/>
    </div>
  </div>
  <page-counter :instances-per-page="resultsPerPage" :total-instances="aggregatedDocs.length" :current-page="currentPage"
                @changePage="changePage"/>
  <button class="button is-primary is-back-button" @click="$emit('back')">Voltar</button>
  <waiting-overlay v-if="toWait" />
</div>
</template>

<script>
import DocumentResult from "./DocumentResult";
import PageCounter from "../generic/PageCounter";
import WaitingOverlay from "../generic/WaitingOverlay.vue";
export default {
name: "DisplayResults",
  components: { PageCounter, DocumentResult, WaitingOverlay },
  data:()=>({
    resultsPerPage: 6,
    currentPage: 0,
    aggregatedDocs:[],
    toWait: false,
  }),
  props:{
    documents: {
      type: Array,
    },
    term: {
      type: String,
    },
    searchMode: {
      type: Object,
    }

  },
  methods:{
    showDocument(id){
      this.$router.push({name: 'Document', params: {id: id.toString(), documents:this.documents, term:this.term,
        searchMode:this.searchMode}})
    },
    showPage(page){
      this.$router.push({name: 'Document', params: {id: page.id.toString(), documents:this.documents, term:this.term,
          page:page.page, searchMode:this.searchMode}})
    },
    async changePage(page) {
      this.toWait = true;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      await new Promise(resolve => setTimeout(resolve, 500));
      this.currentPage = page;
      this.toWait = false;
    },
    maxDocument(){
      let results = Math.min(this.resultsPerPage, this.aggregatedDocs.length, this.aggregatedDocs.length-this.currentPage*this.resultsPerPage)
      return results
    },
    filterDocuments(){
      let documents = [];
      for(let i=this.currentPage*this.resultsPerPage; i<this.aggregatedDocs.length; i++){
        documents.push(this.aggregatedDocs[i])
      }
      return documents
    },
    createDocuments(){
      let documents = [];
      let ids = [];
      for(let i=0; i<this.documents.length; i++) {
        if (ids.includes(this.documents[i].document.id)) {
          let page = {number: this.documents[i].number, text: this.documents[i].text};
          documents[this.documents[i].document.id].pages.push(page);
        } else {
          let page = {number: this.documents[i].number, text: this.documents[i].text};
          let document = {
            date: this.documents[i].document.date, file: this.documents[i].document.file,
            id: this.documents[i].document.id, title: this.documents[i].document.title, pages: [page]
          }
          documents[this.documents[i].document.id] = document;
          ids.push(this.documents[i].document.id)
        }
      }
      let newDocuments = [];
      for(let i=0; i<documents.length; i++) {
        if(documents[i] != null){
          newDocuments.push(documents[i]);
        }
      }
      this.aggregatedDocs = newDocuments;
    }
  },
  created() {
      this.createDocuments()
  }
}
</script>

<style scoped>
.is-back-button{
  width: 5em;
  height: 3em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
}

.button.is-primary{
  background-color: #f5993d;
}

.button.is-primary:hover{
  background-color: #ff8000;
}
</style>