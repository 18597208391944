<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="modal-content">
        <div class="box">
          <div class="columns is-vcentered is-80-percent">
            <div class="column is-1">
              <a @click="previousImage" v-if="currentImage > 0">
                <i class="fas fa-chevron-circle-left is-pointer"></i>
              </a>
            </div>
            <div class="column has-text-centered">
              <spinner v-show="!imageLoaded" />
              <img v-show="imageLoaded" class="is-image-width" :src="getImage()" alt=""
                   @load="imageLoaded = true">
            </div>
            <div class="column is-1">
              <a @click="nextImage" v-if="currentImage < (images.length - 1)">
                <i class="fas fa-chevron-circle-right is-pointer"></i>
              </a>
            </div>
          </div>
          <!--<div class="columns">
            <div class="column">
              <p v-html="images[currentImage].caption"></p>
            </div>
          </div>-->
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="$emit('close')"></button>
    </div>
  </div>
</template>

<script>
import Spinner from "./Spinner";
export default {
name: "ImageDisplayer",
  components: {Spinner},
  data:()=>({
    currentImage: 0,
    imageLoaded: false,
  }),
props:{
  images:{
    type: Array,
  },
  startingImage:{
    type: Number,
  },
},
methods:{
  getImage() {
    return 'http://' + location.host + '/pluralismo-juridico/news/' + this.images[this.currentImage].image;
  },
  previousImage(){
    this.imageLoaded = false;
    this.currentImage--;
  },
  nextImage(){
    this.imageLoaded = false;
    this.currentImage++;
  },
},
created() {
  if(this.startingImage != null){
    this.currentImage = this.startingImage;
  }
},
  mounted() {
    window.addEventListener("keyup", e => {
      if((e.code === 'ArrowLeft') && (this.currentImage > 0)){
        this.previousImage()
      }
      if((e.code === 'ArrowRight') && (this.currentImage < (this.images.length - 1))){
        this.nextImage()
      }
    });
  }
}
</script>

<style scoped>
.is-image-width{
  max-width: 670px;
  max-height: 700px;
}
.is-pointer{
  cursor: pointer;
}

.modal-content, .modal-card {
  width: 840px;
}
</style>