<template>
  <div>
    <div v-if="!viewBooks && !viewPapers && !viewThesis">
      <h2 class="is-title is-size-4">Bibliografia</h2>
      <div class="columns">
        <div class="column">
          <a @click="viewBooks = true"><img src="@/assets/books.png"></a>
        </div>
        <div class="column">
          <a @click="viewPapers = true"><img src="@/assets/articles.png"></a>
        </div>
        <div class="column">
          <a @click="viewThesis = true"><img src="@/assets/thesis.png"></a>
        </div>
      </div>
    </div>
    <books v-if="viewBooks" @back="viewBooks = false"/>
    <papers v-if="viewPapers" @back="viewPapers = false" />
    <thesis v-if="viewThesis" @back="viewThesis = false" />
  </div>
</template>

<script>
import Books from "../components/partial/Books";
import Papers from "../components/partial/Papers";
import Thesis from "../components/partial/Thesis";
export default {
name: "Bibliography",
  components: {Thesis, Papers, Books},
  data:()=>({
    viewBooks: false,
    viewPapers: false,
    viewThesis: false,
  }),
}
</script>

<style scoped>
.is-title{
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  color: #330000;
}
</style>