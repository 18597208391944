<template>
  <div>
    <div class="columns is-new is-vcentered">
      <div class="column">
        <h2 class="is-title is-size-5">Participação na <i>ASWAD 10th Biennial Conference</i></h2>
        <p class="block">João Figueiredo, investigador do projeto ‘Pluralismo Jurídico no Império Português (séculos XVIII-XX)’,
          apresentou o seu trabalho ‘A restituição de peças angolanas enquanto mote de pesquisa e apuramento da verdade
          histórica’ na <i>10th Biennial Conference da Association for the Study of the Worldwide African Diaspora</i> (ASWAD),
          no William & Mary College, Williamsburg (VA), onde participou no painel <i>Restitution and Repatriation Claims:
            African Cultural Heritage in Western Museums</i>, organizado e comentado por Ana-Lúcia Araújo (Howard College).  </p>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <img src="@/assets/news/ASWAD/ASWAD10.png">
          </div>
        </div>
        <pictures :pictures="aswadPictures"/>
      </div>
    </div>

    <div class="columns is-new is-vcentered">
      <div class="column">
        <h2 class="is-title is-size-5">Participação na <i>62nd Annual Meeting of the African Studies Association</i></h2>
        <p class="block">Nos dias 22 e 23 de novembro de 2019, João Figueiredo, investigador do projeto ‘Pluralismo Jurídico no
          Império Português (séculos XVIII-XX)’, participou na <i>62nd Annual Meeting of the African Studies Association</i>
          (ASA), que decorreu no Marriott Copley Place de Boston. Atuou como <i>discussant</i> do painel <i>Cross-Cultural
            encounters, local botanical knowledge, and scientific networks in nineteenth-century Angola</i>, organizado por
          Jeremy Ball (Dickinson College) e Sara de Albuquerque (IHC-Nova) e oficialmente patrocinado pela <i>Lusophone African
            Studies Organization</i> (LASO). Este painel, em que participaram também Éva Sebestyén (CEA-UP) e Jelmer Vos
          (University of Glasgow), proporcionou uma discussão em torno dos sistemas normativos autóctones da antiga colónia
          Portuguesa, no concernente à demarcação e posse de terras, e ao uso de recursos e conhecimentos fitológicos. A título
          individual apresentou também o paper ‘<i>Restitution as a colonial maneuver: the case of the Portuguese Dundo Museum
            (Angola, 1936-75)</i>’, ao painel ‘<i>Museums and Artifacts</i>’, no qual expõe parte da sua pesquisa sobre coleções
          etnográficas e o impacto que os Museus Etnográficos tiveram na forma como a codificação de normas africanas e
          ‘usos e costumes’ evoluiu ao longo do século XX.
        </p>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <img src="@/assets/news/ASA/ASA62.png">
          </div>
        </div>
        <pictures :pictures="asaPictures"/>
      </div>
    </div>

    <div class="columns is-new is-vcentered">
      <div class="column">
        <h2 class="is-title is-size-5">Participação na <i>Norms and Empires Lecture Series</i></h2>
        <p class="block">No âmbito do projeto 'Pluralismo Jurídico no Império Português (séculos XVIII-XX)', a Prof. Doutora Ângela Barreto Xavier, participou na <i>Norms and Empires
          Lecture Series</i> com uma comunicação intitulada <i>Local normativity and the Portuguese imperial order. The case of
          Goa in the 16th and 17th centuries</i>. Para mais informações consultar o site da  <a href='https://www.rg.mpg.de/1822946/event-19-12-03-norms-and-empires-lecture-series.html'><i>Norms
          and Empires Lecture Series</i></a>.
        </p>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <img src="@/assets/news/NELS/Norms and Empires Lecture Series.png">
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-new is-vcentered">
      <div class="column">
        <h2 class="is-title is-size-5">III Encontro Hispano-Luso de Historiadores do Direito</h2>
        <p class="block">Co-organizado e co-dirigido científicamente por <a href='$cnsLink'>Cristina Nogueira da Silva</a>, investigadora
          principal do projeto <i>Pluralismo Jurídico no Império Português (séculos XVIII-XX)</i> (LEGALPL), o <i>III Encontro
            Hispano-Luso de Historiadores do Direito</i> teve lugar na Faculdade de Direito da Universidade de Lisboa e na Faculdade
          de Direito da NOVA, respetivamente nos dias 17 e 18 de junho, de 2019. Reunindo contribuições de mais de 50
          investigadores de universidades peninsulares e latino-americanas, agregados em 12 sessões paralelas, o evento
          contribuiu para o consolidar de uma comunidade científica vibrante e em franca expansão, contando com <i>key-note
            lectures </i> de Pietro Costa (Universidade de Florença) e de Lauren Benton (<i>Wanderbilt Law School</i>) – consultora
          do projeto LEGALPL.
        </p>

        <p class="block">Para além da participação executiva e científica de Cristina Nogueira da Silva, que apresentou ao painel ‘Pluralismo
          Jurídico no Império Português (época moderna)’ a comunicação ‘Pluralismo Jurídico ou Multinormatividade?’, o III
          Encontro contou com o secretariado de Yamê Paiva, bolseira do projeto LEGALPL, e com a intervenção científica de
          vários dos investigadores afetos ao projeto. Os <i>abstracts</i> dos paineis ‘<a href='$session8Link' target='_blank'>Pluralismo
            Jurídico no Império Português (época contemporânea)</a>’, em que participaram Fernanda Thomaz, João
          Figueiredo, Ricardo Roque, Samory Monteiro, Luis Oliveira, e ‘<a href='$session12Link' target='_blank'>Pluralismo
            Jurídico no Império Português (época moderna)</a>’, da qual fizeram parte Cristina Nogueira da Silva, Pedro Cardim,
          Yamê Paiva, Nuno Camarinhas e Ângela Barreto Xavier, testemunham os projetos individuais em curso no âmbito do LEGALPL.
          No seguimento do III Encontro, Lauren Benton lecionou uma aula aberta sobre ‘pluralismo legal’, lançando um repto a
          todos para participarem encontro anual da <a href='https://aslh.net/conference/2019-annual-meeting/' target='_blank'>American
            Society for Legal History</a> (21 a 24 de novembro, Boston), a que preside.</p>

        <p class="block">O III Encontro Hispano-Luso de Historiadores do Direito foi co-organizado pela <a href='https://www.fd.unl.pt/' target='_blank'>Faculdade
          de Direito da Universidade NOVA de Lisboa</a>, pelo <a href='https://cedis.fd.unl.pt/en/' target='_blank'>CEDIS –
          Centro de Investigação e Desenvolvimento sobre Direito e Sociedade</a>, pela <a href='http://www.fd.ulisboa.pt/en/' target='_blank'>Faculdade
          de Direito da Universidade de Lisboa</a>, pelo <a href='https://www.thdulisboa.com/' target='_blank'>THD-UL – Centro de
          Investigação em Teoria e História do Direito</a> e pela <a href='https://www.uam.es/Derecho/Home.htm' target='_blank'>Facultad
          de Derecho da Universidad Autónoma de Madrid</a>. Contou com os apoios da <a href='https://www.fct.pt/index.phtml.pt' target='_blank'>FCT
          - Fundação para a Ciência e a Tecnologia</a>, do projeto LEGALP, da <a href='https://www.facebook.com/JurisnovaFDUNL/' target='_blank'>Jurisnova
          – Associação da Faculdade de Direito da Universidade NOVA de Lisboa</a> e do <a href='https://www.thdulisboa.com/ihdpp-instituto' target='_blank'>IHDPP
          – Instituto de História do Direito e do Pensamento Político (FDUL)</a>.</p>

        <p class="block">O programa completo do evento pode ser encontrado <a href='$programLink' target='_blank'>aqui</a>.</p>


      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <img src="@/assets/news1.png">
          </div>
        </div>
        <pictures :pictures="ehlhdPictures"/>
      </div>
    </div>

  </div>
</template>

<script>
import Pictures from "../components/partial/Pictures";
export default {
name: "News",
  components: {Pictures},
  data:()=>({
    aswadPictures:[{image:'ASWAD/ASWAD 1.jpg', caption:''}, {image:'ASWAD/ASWAD 2.jpg', caption:''}],
    asaPictures:[{image:'ASA/ASA 1.jpg', caption:''}, {image:'ASA/ASA 2.jpg', caption:''}],
    ehlhdPictures:[
        {image:'EHLHD/EHLHD1.jpg', caption:''}, {image:'EHLHD/EHLHD2.jpg', caption:''},
      {image:'EHLHD/EHLHD3.jpg', caption:''}, {image:'EHLHD/EHLHD4.jpg', caption:''},
      {image:'EHLHD/EHLHD5.jpg', caption:''}, {image:'EHLHD/EHLHD6.jpg', caption:''},
      {image:'EHLHD/EHLHD7.jpg', caption:''}, {image:'EHLHD/EHLHD8.jpg', caption:''},
      {image:'EHLHD/EHLHD9.jpg', caption:''}, {image:'EHLHD/EHLHD10.jpg', caption:''}
      ]
  }),
}
</script>

<style scoped>
.is-title{
  text-align: justify;
  margin-bottom: 40px;
  font-weight: bold;
  color: #330000;
}

.is-new{
  border-bottom: 1px dotted #636363;
  margin-bottom: 60px;
}
</style>