<template>
  <nav class="pagination is-centered" role="navigation">
    <ul class="pagination-list">
      <li>
        <a class="pagination-previous" @click="previous" :disabled="currentPage <= 0">&lt;</a>
      </li>
      <li v-for="(page, index) in clipPages()" :key="index">
        <a class="pagination-link is-current" disabled v-if="page === currentPage+1">{{ page }}</a>
        <a class="pagination-link" v-else  @click="changePage(page-1)">{{ page }}</a>
      </li>
      <li>
        <a class="pagination-next" @click="next" :disabled="currentPage >= (Math.ceil(totalInstances/instancesPerPage) - 1)">></a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "PageCounter",
  data:()=>({
  }),
  props:{
    totalInstances:{
      type: Number,
      required: true,
    },
    instancesPerPage:{
      type: Number,
      required: true,
    },
    currentPage:{
      type: Number,
      required: true,
    },
  },
  methods:{
    previous(){
      if(this.currentPage > 0){
        this.$emit('changePage', this.currentPage - 1)
      }
    },
    next(){
      if(this.currentPage < (Math.ceil(this.totalInstances/this.instancesPerPage) - 1)) {
        this.$emit('changePage', this.currentPage + 1)
      }
    },
    changePage(page){
      this.$emit('changePage', page)
    },
    clipPages(){
      let pages = []
      let totalPages = Math.ceil(this.totalInstances/this.instancesPerPage) + 1;
      for(let i=1; i<totalPages; i++){
        if(pages.length < 7){
          if((totalPages > 7) && (i > totalPages - 8) && (this.currentPage >= 8)){
            pages.push(i);
          }else if(this.currentPage > 6){
            if((i < this.currentPage + 5) && (i > this.currentPage - 3)){
              pages.push(i);
            }
          }else{
            if((i < 8) && (this.currentPage < 8)){
              pages.push(i);
            }
          }
        }
      }
      return pages
    },
  },
}
</script>

<style scoped>

.is-current{
  background-color: #f5993d;
}

a:hover:not([disabled]){
  background-color: #ff8000 !important;
}

</style>