<template>
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="container spinner-container">
                <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WaitingOverlay"
    }
</script>

<style scoped>
    .half-circle-spinner, .half-circle-spinner * {
        box-sizing: border-box;
    }

    .half-circle-spinner {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        position: relative;
    }

    .half-circle-spinner .circle {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: calc(60px / 10) solid transparent;
    }

    .half-circle-spinner .circle.circle-1 {
        border-top-color: #ff8000;
        animation: half-circle-spinner-animation 1s infinite;
    }

    .half-circle-spinner .circle.circle-2 {
        border-bottom-color: #ff8000;
        animation: half-circle-spinner-animation 1s infinite alternate;
    }

    @keyframes half-circle-spinner-animation {
        0% {
            transform: rotate(0deg);

        }
        100%{
            transform: rotate(360deg);
        }
    }

    .spinner-container{
        width: 80px;
        height: 80px;
    }
</style>