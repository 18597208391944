<template>
    <div class="modal" :class="isActive">
        <div class="modal-background" @click="closeOverlay"></div>
        <div class="modal-content">
            <div class="notification" :class="informationType">
                <div class="error-title">
                    <h2 class="title">{{titleType}}</h2>
                    <p class="is-size-6">&nbsp;</p>
                    <p class="is-size-6" :class="isWhite" v-if="isHtml" v-html="overlayMessage"></p>
                    <p class="is-size-6" :class="isWhite" v-else>{{overlayMessage}}</p>
                    <div class="field is-grouped is-grouped-centered">
                        <button class="button is-inverted ok-button" :class="informationType"
                                @click="closeOverlay">OK</button>
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="closeOverlay"></button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MessageOverlay",
        data: () => ({
            isActive: 'is-active',
        }),
        computed:{
            titleType: function () {
                if(this.informationType === 'is-info'){
                    return 'Informação';
                }else if(this.informationType === 'is-light'){
                    return 'Informação';
                }else if(this.informationType === 'is-rules'){
                    return 'Modalidades de Pesquisa';
                }else{
                    return 'Erro';
                }
            },
            isWhite: function () {
                if(this.informationType !== 'is-light'){
                    return 'is-white-message';
                }else{
                    return ''
                }
            },
        },
        props:{
            overlayMessage:{
                type: String,
                required: true,
            },
            informationType:{
                type: String,
                required: true,
            },
            isHtml:{
                type: Boolean,
                required: false,
            }
        },
        methods:{
            closeOverlay(){
                this.isActive  = '';
                this.$emit('closed');
            }
        }
    }
</script>

<style scoped>
.is-white-message{
  color: #fff2e6;
}

.ok-button{
    margin-top: 2em;
}
</style>