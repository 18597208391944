<template>
<div>
  <h2 class="is-title is-size-5">Documentos de {{this.work.title}}</h2>
  <div class="box is-document-box" v-for="n in maxDocument()" :key="n">
    <p>Título: {{filterDocuments()[n-1].title}}</p>
    <p>Data: {{filterDocuments()[n-1].date.year}}</p>
    <button class="button is-display-button is-primary" @click="viewDocument(filterDocuments()[n-1].id)">Ver</button>
  </div>
  <page-counter :current-page="currentPage" :instances-per-page="resultsPerPage" :total-instances="documents.length"
                @changePage="changePage"/>
  <button class="button is-primary is-back-button" @click="back">Voltar</button>
  <waiting-overlay v-if="isWait" />
</div>
</template>

<script>
import WaitingOverlay from "../generic/WaitingOverlay";
import PageCounter from "../generic/PageCounter";
export default {
name: "ViewDocuments",
  components: {PageCounter, WaitingOverlay},
  data:()=>({
    documents: [],
    currentPage: 0,
    resultsPerPage: 6,
    isWait: false,
  }),
  props:{
    work: Object
  },
  methods:{
    async getDocuments(work){
      const opts = {
        method: 'POST',
        headers: {'Content-Type': 'application/json; charset=UTF-8"'},
        body: JSON.stringify({id:work.id})
      };
      const res = await fetch('/pluralismo-juridico/API/public/index.php/SearchAPI/getDocuments', opts).
      then(res => res.json());
      if(res !== null){
        this.documents = res.documents;
      }else{
        this.documents = []
      }
    },
    viewDocument(id) {
      this.$router.push({name: 'Document', params: {id: id.toString(), work:this.work}})
    },
    back(){
      this.$emit('back')
    },
    changePage(page) {
      this.currentPage = page;
    },
    maxDocument(){
      let results = Math.min(this.resultsPerPage, this.documents.length, this.documents.length-this.currentPage*this.resultsPerPage)
      return results
    },
    filterDocuments(){
      let documents = [];
      for(let i=this.currentPage*this.resultsPerPage; i<this.documents.length; i++){
        documents.push(this.documents[i])
      }
      return documents
    },
  },
  async created() {
    this.isWait = true;
    await this.getDocuments(this.work);
    this.isWait = false;
  }
}
</script>

<style scoped>
.is-title{
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  color: #330000;
}

.is-display-button{
  margin-top: 2em;
}

.button.is-primary{
  background-color: #f5993d;
}

.button.is-primary:hover{
  background-color: #ff8000;
}

.is-document-box{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.is-back-button{
  width: 5em;
  height: 3em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
}
</style>