<template>
  <div class="all-papers">
    <h2 class="is-title is-size-4">Bibliografia - Artigos</h2>
    <div class="columns">
      <div class="column">
        <p>THOMAZ, Fernanda do N. (2012), ‘Codificação dos costumes: Gonçalves Cota e os códigos jurídicos para
          os africanos de Moçambique’, <a href='https://www.africanos.eu/index.php/pt/virtuemart/livros/africana-studia/africana-studia-n%C2%BA-26,-2016-93-98-99-detail' target='_blank'>Africana
            Studia</a>, 19, 2 (2012), 105-116.</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <p>OLIVEIRA, Luís Pedroso de Lima Cabral de (2011), ‘<a href='http://goa.fflch.usp.br/sites/goa.fflch.usp.br/files/rev.19.luis%20revisado%20bere.pdf' target='_blank'>Direito,
          literatura e prática judicial na Goa de outrora: o caso dos advogados provisionários</a>’, <a href='http://www.revistas.usp.br/viaatlantica/issue/view/4220' target='_blank'>Via
          Atlântica</a>, 19 (2011), 75-86.</p>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <button class="button is-back" @click="$emit('back')">Voltar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Papers"
}
</script>

<style scoped>
.all-papers{
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.is-title{
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  color: #330000;
}

.is-back{
  background-color: #ff8000;
  color: #fff2e6;
  font-weight: bold;
}
</style>