<template>
  <div :style="{'background-image': `url(${require('../../assets/archive.png')})`}" :class="setSize()">
    <span class="buttonText">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "ArchiveButton",
  props:{
    text:String,
    size:String,
  },
  methods:{
    setSize(){
      if (this.size === "large"){
        return "background-large";
      }else{
        return "background";
      }
    }
  },
}
</script>

<style scoped>
.background{
  width: 260px;
  height: 150px;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
}

.background-large{
  width: 320px;
  height: 170px;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
}

.background span{
  font-size: 20px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: #FF8000;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.background-large span{
  font-size: 28px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: #FF8000;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
</style>