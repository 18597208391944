<template>
  <div>
    <h2 class="is-title is-size-4">Objetivos</h2>
    <div class="columns is-vcentered">
      <div class="column">
        <div class="columns">
          <div class="column">
            <p class="block">O objetivo deste projeto é estudar o encontro entre a ordem jurídica
              portuguesa e as ordens jurídicas nativas nos territórios ultramarinos portugueses nos séculos XVIII-XX. Pretende-se
              saber como é que estas ordens foram classificadas, como foram usadas por colonizadores e colonizados,
              como interagiram e foram transformadas pelas situações coloniais, em várias cronologias.</p>

            <p class="block is-final-part">O primeiro nível que iremos considerar é então aquele em que se localizam os
              agentes coloniais, na metrópole e nas colónias (legisladores, académicos ou juízes e oficiais envolvidos na
              administração da justiça). O segundo foco será o recurso à justiça colonial pelas populações nativas. Mapearemos
              também as estruturas judiciais ultramarinas durante o período considerado,
              bem como os agentes da justiça. Fontes privilegiadas serão a doutrina jurídica, a legislação, debates políticos,
              relatórios administrativos e judiciais, jurisprudência e códigos de “usos e costumes”. A investigação será orientada
              pelas seguintes questões: de que modo os sujeitos envolvidos usaram estrategicamente
              o pluralismo? até onde foi a permeabilidade das ordens nativas ao direito europeu e vice-versa e como se
              transformaram mutuamente?</p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p class="block" style='padding-top: 30px;'>This project aims to study the encounter between the Portuguese and  local  legal orders
              in the Portuguese Empire, from the 18th to the 20th century. We want to know how these legal orders were
              classified, how they interacted and how they were used by colonizers and colonized peoples in different
              chronological periods, as well as the changes that occurred during the epoch under consideration.
            </p>

            <p class="block">First, we will consider colonial agents of justice (legislators, colonial judges) and their activity. Then, we will
              focus on local people who applied to justice. We will analyze political discussions, legislation, doctrinal texts,
              administrative and judicial reports, jurisprudence and codes of “uses and customs”. Our inquiry will be guided by some
              unifying questions: How high was the permeability of local and Portuguese law towards each other?; How were they both
              transformed by the colonial circumstances? How far did the strategic use of legal pluralism by both the authorities
              and the “subjects” go?
            </p>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="is-panel">
              <img src="@/assets/TribunalSena.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Objectives',
  components: {
  },
  data:()=>({
  }),
  created() {
  }
}
</script>

<style>
.is-final-part{
  border-bottom: 1px dotted #636363;
  padding-bottom: 40px;
}

.is-panel {
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}

.is-title{
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  color: #330000;
}
</style>
