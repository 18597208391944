<template>
  <div class="modal is-active">
    <div class="modal-background" @click="close"></div>
    <div class="modal-content">
      <div class="box is-bibliography">
        <div v-html="modalContent">
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="close"></button>
  </div>
</template>

<script>
export default {
  name: "TeamWork",
  data:()=>({

  }),
  props:{
    modalContent: {
      type: String
    },
  },
  methods:{
    close(){
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.is-bibliography{
  padding: 30px;
}
</style>