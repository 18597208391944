<template>
  <div>
    <div class="columns is-multiline is-vcentered is-gapless is-centered">
      <div class="column is-one-quarter has-text-centered" v-for="(image, index) in pictures" :key="index">
        <img class="is-image-width is-cursor-pointer" :src="getImage(index)" alt="" @click="setImage(index)">
      </div>
    </div>
    <image-displayer :images="pictures" :starting-image="clickedImage" v-if="displayAll"
                     @close="displayAll = false"></image-displayer>
  </div>
</template>

<script>
import ImageDisplayer from "../generic/ImageDisplayer";
export default {
name: "Pictures",
  components: {ImageDisplayer},
  data:()=>({
    displayAll: false,
    clickedImage: 0,
  }),
  props:{
    pictures:{
      type: Array,
    }
  },
  methods:{
    getImage(index){
      return 'http://' + location.host + '/pluralismo-juridico/news/' + this.pictures[index].image;
    },
    setImage(index){
      this.clickedImage = index;
      this.displayAll = true;
    }
  }
}
</script>

<style scoped>
.is-image-width{
  max-width: 140px;
  max-height: 140px;
}
.is-cursor-pointer{
  cursor: pointer;
}
</style>