<template>
<div>
  <div>
    <div class="box is-work-box" v-for="(work, index) in works" :key="index">
      <p>Título: {{work.title}}</p>
      <p>Local de Publicação: {{work.place}}</p>
      <p>Data de Início: {{work.startDate.year}}</p>
      <p>Data de Fim: {{work.endDate.year}}</p>
      <button class="button is-display-button is-primary" @click="viewWork(index)">Ver</button>
    </div>
    <waiting-overlay v-if="isWait" />
  </div>
</div>
</template>

<script>
import WaitingOverlay from "../generic/WaitingOverlay";
export default {
name: "ViewWorks",
  components: {WaitingOverlay},
  data:()=>({
    works: [],
    isWait: false,
  }),
  methods:{
    async getWorks(){
      const opts = {
        method: 'POST',
        headers: {'Content-Type': 'application/json; charset=UTF-8"'},
      };
      const res = await fetch('/pluralismo-juridico/API/public/index.php/SearchAPI/getWorks', opts).
      then(res => res.json());
      if(res !== null){
        this.works = res.works;
      }else{
        this.works = []
      }
    },
    viewWork(index){
      this.$emit('view', this.works[index])
    }
  },
  async created() {
    this.isWait = true;
    await this.getWorks();
    this.isWait = false;
  }
}
</script>

<style scoped>
.is-work-box{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.is-display-button{
  margin-top: 2em;
}

.button.is-primary{
  background-color: #f5993d;
}

.button.is-primary:hover{
  background-color: #ff8000;
}
</style>