<template>
  <div class="all-thesis">
    <h2 class="is-title is-size-4">Bibliografia - Teses</h2>
    <div class="columns">
      <div class="column">
        <p>ALMEIDA, Joana Estorninho de (2008), <a href='https://repositorio.ul.pt/handle/10451/314' target='_blank'>A
          Cultura Burocrática Ministerial: Repartições, Empregados e Quotidiano das Secretarias de Estado na primeira metade do
          século XIX</a>. Tese de Doutoramento (Lisboa: ICS-Universidade de Lisboa, 2008).</p>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <button class="button is-back" @click="$emit('back')">Voltar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Thesis"
}
</script>

<style scoped>
.all-thesis{
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.is-title{
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  color: #330000;
}

.is-back{
  background-color: #ff8000;
  color: #fff2e6;
  font-weight: bold;
}
</style>