<template>
  <div>
    <div class="columns">
      <div class="column">
        <PDFJSViewer class="is-viewer" :path="path" :file-name="fileName"
                     :page="page" :term="term"/>
      </div>
      <div class="column is-one-third">
        <div class="box">
          <p>Título: {{title}}</p>
          <p>Ano: {{year}}</p>
        </div>
      </div>
    </div>
    <button class="button is-primary is-back-button" @click="back">Voltar</button>
    <waiting-overlay v-if="isWait"/>
  </div>
</template>

<script>
import PDFJSViewer from "../components/partial/PDFJSViewer";
import WaitingOverlay from "../components/generic/WaitingOverlay";
export default {
name: "Document",
  components: {WaitingOverlay, PDFJSViewer},
  props:{
    id: String,
  },
  data:()=>({
    docId: NaN,
    path: '/pdfs/pdfjs-2.8.335-dist/web/viewer.html', //path of the PDF.js viewer.html
    fileName: '',
    page: 1,
    term: '',
    title: '',
    year: '',
    isWait: false,
  }),
  methods:{
    async getDocument(){
      this.isWait = true;
      const opts = {
        method: 'POST',
        headers: {'Content-Type': 'application/json; charset=UTF-8"'},
        body: JSON.stringify({id:this.docId})
      };
      const res = await fetch('/pluralismo-juridico/API/public/index.php/SearchAPI/getDocument', opts).
      then(res => res.json());
      if(res !== null){
        this.fileName = res.document[0].file;
        this.title = res.document[0].title;
        this.year = res.document[0].date.year;
      }else{
        //this.$emit('searched', result);
      }
      this.isWait = false;
    },
    back(){
      if(this.$route.params.documents != null){
        this.$router.push({name: 'Search', params: {documents:this.$route.params.documents,
            term:this.$route.params.term, searchMode:this.$route.params.searchMode}});
      }else if(this.$route.params.work != null){
        this.$router.push({name: 'Search', params: {work:this.$route.params.work}});
      }else{
        this.$router.push('/pesquisa');
      }
    }
  },
  created() {
    if(Number.isInteger(parseInt(this.id))){
      this.docId = parseInt(this.id);
      this.getDocument()
    }
    if(this.$route.params.term != null){
      this.term = this.$route.params.term;
    }
    if(this.$route.params.page != null){
      this.page = this.$route.params.page;
    }
  }
}
</script>

<style scoped>
.is-viewer{
  height: 55em;
  margin-left: auto;
  margin-right: auto;
}

.is-back-button{
  width: 5em;
  height: 3em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
}

.button.is-primary{
  background-color: #f5993d;
}

.button.is-primary:hover{
  background-color: #ff8000;
}
</style>