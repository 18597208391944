<template>
  <div>
    <div v-if="!uses && !doctrine && !juri && !links">
      <div class="columns">
        <div class="column">
          <p class="block"><b>O Arquivo Digital compõe-se de dois conjuntos de informação:</b></p>
          <p class="block">Uma base de dados de LEGISLAÇÃO COLONIAL impressa (ver Legislação Colonial). <br />
          Nesta base de dados o utilizador pode pesquisar em 88 volumes contendo legislação respeitante aos territórios coloniais de Portugal, 
          entre 1910 e 1970, pesquisáveis em regime de «pesquisa livre». <br /><br />
          A colecção constituiu-se a partir de três coleções de legislação impressas: a Collecção da Legislação colonial da República 
          Portugueza (1910-1933, 34 volumes), o Boletim de Legislação Ultramarina Portuguesa (1934-1946, 24 volumes), e a Nova Legislação 
          Ultramarina (1953-1970, 30 volumes). Disponibiliza cerca de 67.5000 páginas digitalizadas, cobrindo o período de 1910 até 1970, 
          com poucas falhas cronológicas.<br /><br />
          Esta base de dados completa a base de dados de legislação colonial já disponível no site O Governo dos Outros. Imaginários 
          Políticos no Império Português (1496-1961), também financiado pela Fundação para a Ciência e Tecnologia, que cobre o período 
          cronológico imediatamente anterior (1496-1961), (http://www.governodosoutros.ics.ul.pt/). Com isso os investigadores, 
          independentemente do lugar onde se encontrem, passam a ter acesso, de ora em diante, a um corpus documental de grande 
          relevância e abrangência cronológica para o conhecimento mais profundo dos temas explorados no âmbito destes dois projectos. <br /><br />
          A concretização desta base de dados foi possível graças à disponibilização das colecções no Arquivo Histórico Ultramarino, onde foram 
          integralmente digitalizadas. Agradece-se a colaboração da sua Diretora, Professora Dra. Anna Canas, e a generosa colaboração dos 
          funcionários do Arquivo, nomeadamente Isabel Amado, Manuela Portugal, Jorge Nascimento e Zélia Goia, que tornaram possível este 
          trabalho em plena Pandemia do Covid. Igualmente imprescindível foi o trabalho desenvolvido pela Bolseira Doutorada do Projeto, a 
          Professora Dra. Yamê Paiva, em todas as fases do processo de criação desta Base de Dados. Sem a sua colaboração e persistência 
          esta não existiria.</p>

 
          <p class="block">Além da base de dados, o site integra também um conjunto de ÍNDICES, ainda em construção, com informação relevante para o tema do projeto:<br />
                                i)	Um índice de registos, coleções e codificações de «usos e costumes»;<br />
                                ii)	Um índice de textos doutrinais e de jurisprudência; <br />
                                iii)	Um índice de coleções, reportórios e índices de legislação colonial.<br />

          </p>
        </div>
        <div class="column is-half"> 
          <div class="columns is-half"> 
            <div class="column" @click="goToSearch">
              <archive-button :text="'Legislação Colonial'" :size="'large'"/>
            </div>
          </div>
          <div class="columns is-half"> 
            <div class="column" @click="goToIndexes">
              <archive-button :text="'Índices'" :size="'large'"/>
            </div>
          </div>
          <!--<div class="columns"> 
            <div class="column" @click="uses = true">
              <archive-button :text="'Usos e Costumes'"/>
            </div>
            <div class="column" @click="doctrine = true">
              <archive-button :text="'Doutrina Colonial'"/>
            </div>
            <div class="column" @click="juri = true">
              <archive-button :text="'Jurisprudência Colonial'"/>
            </div>
          </div>-->
          <div class="columns">
            <div class="column" @click="links = true">
              <archive-button :text="'Links Úteis'" :size="'large'"/>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-bottom-line">
        
      </div>
    </div>
    <uses v-if="uses" @goBack="uses = false"></uses>
    <doctrine v-if="doctrine" @goBack="doctrine = false"></doctrine>
    <juri v-if="juri" @goBack="juri = false"></juri>
    <links v-if="links" @goBack="links = false"></links>
  </div>

</template>

<script>
import ArchiveButton from "../components/generic/ArchiveButton";
import Uses from "../components/partial/Uses";
import Doctrine from "../components/partial/Doctrine";
import Juri from "../components/partial/Juri";
import Links from "../components/partial/Links";
export default {
  name: "Archive",
  components: {Links, Juri, Doctrine, Uses, ArchiveButton},
  data:()=>({
    uses: false,
    doctrine: false,
    juri: false,
    links: false,
  }),
  methods:{
    goToSearch(){
      this.$router.push({name: 'Search'});
    },
    goToIndexes(){
      this.$router.push({name: 'Indexes'});
    }
  }
}
</script>

<style scoped>
.is-bottom-line{
  width:80%;
  margin-left: auto;
  margin-right: auto;
}
</style>