<template>
<div class="all-books">
  <h2 class="is-title is-size-4">Bibliografia - Livros</h2>
  <div class="columns">
    <div class="column">
      <p>MOORE, Sally Falk (1986), <a href='https://books.google.pt/books/about/Social_Facts_and_Fabrications.html?id=tkIHSgAACAAJ&source=kp_book_description&redir_esc=y' target='_blank'>Social
        Facts and Fabrication: «Customary law» on Kilimanjaro, 1880-1980</a> (Cambridge: Cambridge University Press, 1986).</p>
    </div>
  </div>
  <div class="columns books is-vcentered">
    <div class="column is-one-fifth">
      <img src="@/assets/SocialFactsAndFabrications.png">
    </div>
    <div class="column">
      <p class="block">‘…uma
        discussão fascinante dos reportórios normativos tradicionais dos Chagga, que dependiam de um leque amplo de conceções
        sobre a vida social e o cosmos. […] Apesar dos Chagga terem uma cultura vital e bastante envolvida com a nação moderna,
        culturas ‘tribais’ ou ‘folk’ têm sido oficialmente consignadas ao mundo remoto e ficcionado da pré-modernidade. <br/>
        Nesse sentido, Moore recorda aos seus leitores que os antropólogos podem e devem contribuir para resolver dilemas como
        este (e este é um dilema comum no mundo pós-colonial), concretamente, o da institucionalização de uma ideia do
        ‘primitivo’ que não só falha em representar a realidade como impede ativamente certas formas de participação social em
        Estados pluralistas. […] O argumento de que as complexidades da vida merecem ocupar um lugar central no estudo social
        da Lei é poderoso e avançado de forma feliz, conforme a autora demonstra ao longo do seu livro que o que está em jogo
        nesse empreendimento são apostas reais e de alto risco’.
      </p>

    </div>
  </div>

  <div class="columns">
    <div class="column">
      <p>BENTON, Lauren (ed.) e ROSS, Richard J. (ed.), <a href='https://books.google.pt/books/about/Legal_Pluralism_and_Empires_1500_1850.html?id=D4uKgK5IQB0C&printsec=frontcover&source=kp_read_button&redir_esc=y#v=onepage&q&f=false' target='_blank'><i>Legal
        Pluralism and Empires, 1500-1850</i></a>, Nova Iorque, New York University Press, 2013.  </p>
    </div>
  </div>
  <div class="columns books is-vcentered">
    <div class="column is-one-fifth">
      <img src="@/assets/LegalPluralism1500-1850.png">
    </div>
    <div class="column">
      <p class="block">’Os historiadores costumavam imaginar o império como o resultado da extensão da dominação total de um poder
      imperial sobre as suas colónias. Hoje, porém, eles percebem o império como um enquadramento em que as colónias e as
      suas constituições são reguladas por um pluralismo legal: sistemas legais multicêntricos e em camadas diferentes, que
      incorporam e preservam as leis dos sujeitos conquistados. Ao colocar o estudo da lei em diversos impérios do início da
      idade moderna sob a rubrica do pluralismo legal, Legal Pluralism and Empires 1500-1850 oferece tanto a historiadores
      como a estudiosos do direito um muito necessário enquadramento teórico que lhes permite analisarem as políticas legais
      complexas e fluídas dos impérios.’<br />
      (Resumo da obra na contracapa) <br />
      ‘Os benefícios desta abordagem à história imperial, sugerem os editores, incluem o facto de evitar binários restritivos:
      governadores versus governados, Estado versus não-Estado, público versus privado, e semelhantes. De facto, a
      abrangência do conceito ‘pluralismo legal’ – o seu abraçar da complexidade, o seu entendimento inclusivo da lei e da
      legalidade – é testemunha da sua utilidade. A muitos concede agência. Tudo se torna contextual. Nada permanece
      estático. E tanto as dimensões materiais como discursivas se fundem na hora de entender como se geram novas condições
      históricas’. <br />
      (FITZGERALD, Timothy, ‘<a href='https://muse.jhu.edu/article/623748' target='_blank'>Legal Pluralism and Empires,
        1500–1850 ed. by Lauren Benton and Richard J. Ross (review)</a>’, <i>Journal of World History</i>, 26, 4 (2015), 897-901.
      </p>

    </div>
  </div>

  <div class="columns">
    <div class="column">
      <p>BENTON, Lauren, <a href='https://books.google.pt/books/about/A_Search_for_Sovereignty.html?id=i15gAgAAQBAJ&printsec=frontcover&source=kp_read_button&redir_esc=y#v=onepage&q&f=false' target='_blank'><i>A
        Search for Sovereignty: Law and Geography in European Empires, 1400-1900</i></a>, Cambridge, Cambridge University Press, 2010. </p>
    </div>
  </div>
  <div class="columns books is-vcentered">
    <div class="column is-one-fifth">
      <img src="@/assets/SearchForSovereignty.png">
    </div>
    <div class="column">
      <p class="block">‘…o que entendemos hoje como geografia tem pouco em comum com a antiga disciplina imperial, e os territórios
        marcados no mapa são entendidos como apenas uma de várias representações possíveis. Também assistimos a um repensar
        profundo do que pode ser o poder imperial, e de onde o podemos encontrar. A ‘luta’ pelo império levou a que as
        explicações assentes em análises do poderio militar e económico cedessem lugar àquelas centradas em discursos,
        identidades e representações, e depois às focadas no poder disciplinar, na biopolítica e no embodiment, e,
        ultimamente, às que prestam atenção à soberania. Na sua análise abrangente e provocadora, Lauren Benton entra nesta
        discussão para defender que, a partir de uma perspetiva legal, a soberania imperial ‘nunca foi consistente com esta
        imagem produzida pelas manchas monocromáticas dos mapas imperiais’ (p. 2). Pelo contrário, era caracterizada por uma
        série de ‘geografias legais desniveladas’ (p. xii) que ela se propõe depois estudar em detalhe examinando os impérios
        europeus entre 1400 e 1900’. <br />
        OGBORN, Miles, ‘<a href='https://books.google.pt/books/about/A_Search_for_Sovereignty.html?id=i15gAgAAQBAJ&printsec=frontcover&source=kp_read_button&redir_esc=y#v=onepage&q&f=false' target='_blank'>Review
          of Lauren Benton (2010) A Search for Sovereignty: Law and Geography in European Empires, 1400–1900</a>’, <i>The
          American Historical Review</i>, 117, 3 (2012), 814-816.
      </p>

    </div>
  </div>

  <div class="columns">
    <div class="column">
      <button class="button is-back" @click="$emit('back')">Voltar</button>
    </div>
  </div>
</div>
</template>

<script>
export default {
name: "Books"
}
</script>

<style scoped>
.is-title{
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  color: #330000;
}

.all-books{
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.books{
  border-bottom: #3e3e3e dotted thin;
  margin-bottom: 40px;
}

.is-back{
  background-color: #ff8000;
  color: #fff2e6;
  font-weight: bold;
}
</style>